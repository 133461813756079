import { HttpClient } from "@angular/common/http";
import { GLOBAL_SAVE_CONFIG } from "../@config/api";
import { UserInfoService } from "./user-info.service";
import { MatDialog } from "@angular/material";
import { GlobalSaveCancelComponent } from "../@view/global-save/global-save-cancel/global-save-cancel.component";
import { GlobalSaveContinueClaimantUpdateComponent } from "../@view/global-save/global-save-continue-claimant-update/global-save-continue-claimant-update.component";
import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./user-info.service";
import * as i3 from "@angular/material/dialog";
var GlobalSaveService = /** @class */ (function () {
    function GlobalSaveService(http, userInfoService, dialog) {
        this.http = http;
        this.userInfoService = userInfoService;
        this.dialog = dialog;
        this.storage = sessionStorage;
        this.config = GLOBAL_SAVE_CONFIG;
        this.statusActive = "1";
        this.statusNotActive = "0";
        this.sslgId = '';
        this.goToPage = new BehaviorSubject('');
    }
    GlobalSaveService.prototype.getSslgId = function () {
        return this.sslgId;
    };
    GlobalSaveService.prototype.setSslgId = function (value) {
        this.sslgId = value;
    };
    GlobalSaveService.prototype.setStatus = function (status) {
        this.storage.setItem('globalSave', status);
    };
    GlobalSaveService.prototype.setLastClaimant = function (name) {
        this.storage.setItem('globalSaveLastClaimant', name);
    };
    GlobalSaveService.prototype.getLastClaimanName = function () {
        return this.storage.getItem('globalSaveLastClaimant');
    };
    GlobalSaveService.prototype.setLastClaimantFullName = function (name) {
        this.storage.setItem('globalSaveLastClaimantFullName', name);
    };
    GlobalSaveService.prototype.getLastClaimanNameFullName = function () {
        return this.storage.getItem('globalSaveLastClaimantFullName');
    };
    GlobalSaveService.prototype.getStatus = function () {
        return this.storage.getItem('globalSave');
    };
    GlobalSaveService.prototype.getStatusFalse = function () {
        return this.getStatus() == this.statusNotActive;
    };
    GlobalSaveService.prototype.getStatusTrue = function () {
        if (this.isStaff()) {
            return this.getStatus() == this.statusActive;
        }
        return false;
    };
    GlobalSaveService.prototype.isStaff = function () {
        if (this.userInfoService.getCurrentUserRole() == 'EXECUTIVE_STAFF' ||
            this.userInfoService.getCurrentUserRole() == "SSLG_STAFF") {
            return true;
        }
        return false;
    };
    GlobalSaveService.prototype.isExecutiveStaff = function () {
        if (this.userInfoService.getCurrentUserRole() == 'EXECUTIVE_STAFF') {
            return true;
        }
        return false;
    };
    GlobalSaveService.prototype.isClient = function () {
        if (this.userInfoService.getCurrentUserRole() == 'CLIENT') {
            return true;
        }
        return false;
    };
    GlobalSaveService.prototype.isAdjuster = function () {
        if (this.userInfoService.getCurrentUserRole() == 'ADJUSTER') {
            return true;
        }
        return false;
    };
    GlobalSaveService.prototype.on = function () {
        var _this = this;
        this.http.post(this.config.changeStatus, {
            userName: this.getUserName(),
            status: this.statusActive
        })
            .subscribe(function (data) {
            if (data.status == "true") {
                _this.setStatus(_this.statusActive);
                _this.saveLastClaimant(_this.getSslgId()).subscribe(function (data) {
                });
            }
        });
    };
    GlobalSaveService.prototype.off = function () {
        if (this.getStatusTrue()) {
            this.dialog.open(GlobalSaveCancelComponent, {})
                .afterClosed().subscribe(function (j) {
                //close
            });
        }
    };
    GlobalSaveService.prototype.confirmOff = function () {
        return this.http.post(this.config.changeStatus, {
            userName: this.getUserName(),
            status: this.statusNotActive
        });
    };
    GlobalSaveService.prototype.confirmSave = function () {
        return this.http.post(this.config.save, {
            userName: this.getUserName(),
        });
    };
    GlobalSaveService.prototype.removeLastClaimant = function () {
        return this.http.post(this.config.clearLastClaimant, {
            userName: this.getUserName(),
        });
    };
    GlobalSaveService.prototype.continueClaimantUpdate = function () {
        var _this = this;
        if (this.getStatusTrue()) {
            this.http.post(this.config.getLastClaimantInfo, {
                userName: this.getUserName()
            })
                .subscribe(function (data) {
                if (data.status == "true") {
                    /*try {*/
                    if (data.message != "null" && data.message != null) {
                        var result = JSON.parse(data.message);
                        if (result.status == _this.statusActive) {
                            var lastClaimentFullName = result.lastClaimentFullName;
                            var lastClaimentStatus = result.status;
                            var lastClaimentUserName = result.lastClaimentUserName;
                            if (lastClaimentFullName != "" && lastClaimentFullName != "undefined") {
                                _this.setLastClaimantData(lastClaimentStatus, lastClaimentUserName, lastClaimentFullName);
                                _this.dialog.open(GlobalSaveContinueClaimantUpdateComponent, {})
                                    .afterClosed().subscribe(function (j) {
                                    //close
                                });
                            }
                        }
                        else {
                        }
                    }
                    /*} catch (e) {

                    }*/
                }
                else {
                }
            });
        }
    };
    GlobalSaveService.prototype.setLastClaimantData = function (status, lastClaimant, lastClaimentFullName) {
        this.setStatus(status);
        this.setLastClaimant(lastClaimant);
        this.setLastClaimantFullName(lastClaimentFullName);
    };
    GlobalSaveService.prototype.cleareLastClaiment = function () {
        this.setLastClaimant("");
        this.setLastClaimantFullName("");
    };
    GlobalSaveService.prototype.getUserName = function () {
        return this.userInfoService.getCurrentUserName();
    };
    GlobalSaveService.prototype.findForm = function (formName, claimentName) {
        return this.http.post(this.config.getForm, {
            userName: this.getUserName(),
            formName: formName,
            claimentName: claimentName
        });
    };
    GlobalSaveService.prototype.saveForm = function (formName, claimentName, data, formValid) {
        var json = JSON.stringify(data);
        var validation = 0;
        if (formValid) {
            validation = 1;
        }
        return this.http.post(this.config.saveFormData, {
            userName: this.getUserName(),
            formName: formName,
            claimentName: claimentName,
            data: json,
            validation: validation
        });
    };
    GlobalSaveService.prototype.saveLastClaimant = function (claimentName) {
        return this.http.post(this.config.saveLastClaimant, {
            userName: this.getUserName(),
            claimentName: claimentName,
        });
    };
    GlobalSaveService.prototype.getFormData = function (formShortName, formdata, formValid, claimantId, params, runMethod) {
        var _this = this;
        if (this.getStatusTrue()) {
            this.findForm(formShortName, claimantId).subscribe(function (data) {
                if (data.status == "true") {
                    var result = formdata;
                    try {
                        if (data.message != "null" && data.message != null) {
                            result = JSON.parse(data.message);
                        }
                    }
                    catch (e) {
                        _this.saveForm(formShortName, claimantId, formdata, formValid).subscribe(function (data) {
                        });
                    }
                    runMethod(result, params);
                }
                else {
                    if (_this.getStatusTrue()) {
                        _this.saveForm(formShortName, claimantId, formdata, formValid).subscribe(function (data) {
                        });
                    }
                    runMethod(formdata, params);
                }
            });
        }
        else {
            runMethod(formdata, params);
        }
    };
    GlobalSaveService.prototype.checkDisabledFields = function (form) {
        var gsStatus = this.getStatusTrue();
        for (var element in form) {
            if (form[element]) {
                // if(form[element].groups){
                // 	let groups = form[element].groups;
                // 	let groupFactory = form[element].groupPrototype
                //
                // 	for (let element10 in groupFactory) {
                // 		this.fieldDisabledUpdate(groupFactory[element10], gsStatus);
                // 	}
                //
                // 	for (let element1 in groups) {
                // 		if(groups[element1].group){
                // 			let group = groups[element1].group;
                // 			for (let element2 in group) {
                // 				this.fieldDisabledUpdate(group[element2], gsStatus);
                //
                // 				group[element2].disabled = 1;
                // 			}
                // 		}
                // 	}
                // }else{
                if (form[element]._config) {
                    if (form[element]._config.group) {
                        for (var group in form[element].group) {
                            if (form[element].group[group]._config) {
                                if (form[element].group[group]._config.disabled) {
                                }
                                else {
                                    this.fieldDisabledUpdate(form[element].group[group], gsStatus);
                                }
                            }
                            else {
                                this.fieldDisabledUpdate(form[element], gsStatus);
                            }
                        }
                    }
                    else {
                        this.fieldDisabledUpdate(form[element], gsStatus);
                    }
                }
                else {
                    this.fieldDisabledUpdate(form[element], gsStatus);
                }
                //}
            }
        }
        return true;
    };
    GlobalSaveService.prototype.fieldDisabledUpdate = function (el, gsStatus) {
        if (gsStatus) {
            if (el.disabledUpdates && el._config.disabled != true) {
                el.disabledUpdates.next(false);
            }
        }
        else {
            if (el.disabledUpdates) {
                el.disabledUpdates.next(true);
            }
        }
    };
    GlobalSaveService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GlobalSaveService_Factory() { return new GlobalSaveService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UserInfoService), i0.ɵɵinject(i3.MatDialog)); }, token: GlobalSaveService, providedIn: "root" });
    return GlobalSaveService;
}());
export { GlobalSaveService };
