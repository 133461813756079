import * as tslib_1 from "tslib";
import { MatDialog } from '@angular/material/dialog';
import { EventEmitter, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MedicalService } from '../../../../../@core/medical.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from "../../../../../@core/navigation.service";
import { GlobalSaveService } from "../../../../../@core/global-save.service";
import { UserInfoService } from "../../../../../@core/user-info.service";
import { NotificationConfirmationsComponent } from "../../../../notification/confirmations/notification-confirmations.component";
import { MedicalSourcesService } from "../../../../../@core/medical-sources.service";
import { OtheFormNewComponent } from "./othe-form-new/othe-form-new.component";
import { MDEDICAL_TREATING_SOURCES_STATUSES } from "../../../../../@config/status/medical-treating-sources";
import { LoadingService } from "../../../../../../@util/loading/loading.service";
import { DialogService } from "../../../../../../@util/dialog/dialog.service";
import { DELETE_SOURCE_CONFIRM_MESSAGE } from "../medical-treating-sources.config";
var OthersComponent = /** @class */ (function () {
    function OthersComponent(medicalService, dialog, route, globalSaveService, router, dialogService, userInfoService, medicalSourcesService, loadingService, navService) {
        var _this = this;
        this.medicalService = medicalService;
        this.dialog = dialog;
        this.route = route;
        this.globalSaveService = globalSaveService;
        this.router = router;
        this.dialogService = dialogService;
        this.userInfoService = userInfoService;
        this.medicalSourcesService = medicalSourcesService;
        this.loadingService = loadingService;
        this.navService = navService;
        this.title = 'Hospitals';
        this.closeForm = new EventEmitter();
        this.canScrollLeft = false;
        this.canScrollRight = false;
        this.medicalTreatingSourcesStatuses = MDEDICAL_TREATING_SOURCES_STATUSES;
        this.deleteOthersArray = [];
        this.dataSource = new MatTableDataSource([]);
        this.sub = navService.back().subscribe(function (j) { return _this.goBack(); });
    }
    OthersComponent.prototype.ngOnDestroy = function () {
        this.sub.unsubscribe();
    };
    OthersComponent.prototype.goBack = function () {
        this.navService.navigate(['/claimants'], ["" + this.route.snapshot.paramMap.get('sslgId')]);
    };
    OthersComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                ////console.log(this.route.snapshot.parent);
                this.sslgId = this.route.snapshot.parent.params['sslgId'];
                this.loadOthers();
                return [2 /*return*/];
            });
        });
    };
    OthersComponent.prototype.loadOthers = function () {
        var _this = this;
        this.dataSource.paginator = this.paginator;
        this.medicalService.getAllOthers(this.sslgId).subscribe(function (data) {
            _this.hospitals = data;
            _this.dataSource.data = _this.hospitals;
            _this.data = _this.dataSource.connect();
            _this.loadData();
            _this.checkTableHasScroll();
        });
    };
    OthersComponent.prototype.loadData = function () {
        this.dataSource.data = this.hospitals;
        this.data = this.dataSource.connect();
        if (this.route.snapshot.queryParamMap.get('page')) {
            this.paginator.pageIndex = +this.route.snapshot.queryParamMap.get('page');
            this.data.next(this.dataSource._pageData(this.hospitals));
        }
    };
    OthersComponent.prototype.otherInfo = function (visit) {
        var url = this.getUrl();
        this.router.navigate([url + '/' + visit.id + '/info'], {
            queryParams: {
                returnUrl: url,
                page: this.paginator.pageIndex
            }
        });
    };
    OthersComponent.prototype.getUrl = function () {
        return this.router.url.indexOf('?') !== -1 ?
            this.router.url.substring(0, this.router.url.indexOf('?')) : this.router.url;
    };
    OthersComponent.prototype.addDoctor = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dialogRef;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                //const url = this.getUrl();
                //this.router.navigate([url + '/new'], {queryParams: {returnUrl: url, page: this.paginator.pageIndex}});
                document.querySelectorAll('.mat-drawer-content').forEach(function (el) {
                    el.style.overflow = 'hidden';
                });
                dialogRef = this.dialog.open(OtheFormNewComponent, {
                    data: {
                        sslgId: this.sslgId
                    },
                    panelClass: ['mobile-popup', 'with-shadow', 'popup-scrollable'],
                    // panelClass: ['mobile-popup', 'with-shadow'],
                    backdropClass: 'mobile-popup__overlay',
                    disableClose: true
                });
                dialogRef.componentInstance.updateOthersList.subscribe(function (result) {
                    _this.loadOthers();
                });
                dialogRef.afterClosed().subscribe(function (result) {
                    if (result) {
                        if (result.cmd == 'addVisit') {
                            _this.router.navigate(['/claimants/medical/' + _this.sslgId + '/visit-summaries/new'], { queryParams: {
                                    returnUrl: _this.route.snapshot.queryParams['returnUrl'],
                                    catalogItemId: result.data.id,
                                    type: 'OTHER',
                                } });
                        }
                        else {
                            _this.loadOthers();
                        }
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    OthersComponent.prototype.isClaimant = function () {
        return this.userInfoService.isClaimant();
    };
    OthersComponent.prototype.isSystem = function () {
        return this.userInfoService.getCurrentUserNameIsSystem();
    };
    OthersComponent.prototype.delete = function (data, index) {
        var _this = this;
        var dialog = this.dialog.open(NotificationConfirmationsComponent, {
            data: {
                message: "Are you sure you want to delete the source?"
            }
        });
        dialog.afterClosed().subscribe(function (j) {
            try {
                if (j.status) {
                    //alert(data.id)
                    _this.medicalSourcesService.deleteSource(_this.sslgId, data.id).subscribe(function () {
                        var itemIndex = index + _this.paginator.pageSize * _this.paginator.pageIndex;
                        _this.hospitals.splice(itemIndex, 1);
                        _this.loadData();
                    });
                }
            }
            catch (e) {
            }
        });
    };
    OthersComponent.prototype.checkTableHasScroll = function () {
        var _this = this;
        var table = document.querySelector('.i-table');
        if (table) {
            var tableScrollW = document.querySelector('.i-table').scrollWidth;
            var clientW = document.querySelector('.i-table').clientWidth;
            if (tableScrollW !== clientW) {
                this.canScrollRight = true;
            }
        }
        var popupNew = this.route.snapshot.queryParamMap.get('popup');
        if (popupNew) {
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: { popup: null },
                queryParamsHandling: 'merge',
            });
            setTimeout(function () {
                _this.addDoctor();
            });
        }
    };
    OthersComponent.prototype.tableScrolled = function ($event) {
        var scrollLeft = $event.target.scrollLeft;
        var scrollWidth = $event.target.scrollWidth;
        var offsetWidth = $event.target.offsetWidth;
        if (scrollLeft > 0) {
            this.canScrollLeft = true;
        }
        else {
            this.canScrollLeft = false;
        }
        if (scrollWidth - scrollLeft > offsetWidth + 5) {
            this.canScrollRight = true;
        }
        else {
            this.canScrollRight = false;
        }
    };
    OthersComponent.prototype.getTableHeadClass = function () {
        // if (this.isSystem()) {
        //   return 'i-btn-2';
        // }
        return 'i-btn-1';
        // if (this.globalSaveService.getStatusTrue()) {
        //     if (this.isSystem()) {
        //         return 'i-btn-2';
        //     }
        //     return 'i-btn-1';
        // } else {
        //     if (this.isSystem()) {
        //         return 'i-btn-2';
        //     }
        //     return 'i-btn-0';
        // }
    };
    OthersComponent.prototype.isExecutive = function () {
        if (this.userInfoService.getCurrentUserRole() == 'EXECUTIVE_STAFF') {
            return true;
        }
        return false;
    };
    OthersComponent.prototype.onMedicalTreatingSourcesStatusChange = function ($event, doctor, select) {
        var _this = this;
        var newValue = $event.value;
        this.medicalSourcesService.changeStatusSource(this.sslgId, doctor.id, newValue).subscribe(function (data) {
            if (data[status] === false) {
                _this.loadingService.showError([data['message']]);
                select.value = doctor.catalogStatus;
            }
            else {
                _this.loadData();
            }
        }, function () {
            select.value = doctor.catalogStatus;
        });
    };
    OthersComponent.prototype.getMedicalTreatingSourcesStatus = function (doctor) {
        // if (doctor.catalogStatus) {
        return doctor.catalogStatus;
        // }
        // return 0;
    };
    OthersComponent.prototype.toggleDeleteOthersArray = function (e, id) {
        var _this = this;
        if (e.checked) {
            var elExist_1;
            this.deleteOthersArray.forEach(function (el) {
                if (el.id === id) {
                    elExist_1 = el;
                }
            });
            if (!elExist_1) {
                this.deleteOthersArray.push({
                    id: id,
                    sslgId: this.sslgId
                });
            }
        }
        else {
            this.deleteOthersArray.forEach(function (el, index) {
                if (el.id === id) {
                    _this.deleteOthersArray.splice(index, 1);
                }
            });
        }
    };
    OthersComponent.prototype.deleteOthers = function () {
        var _this = this;
        if (!this.deleteOthersArray.length) {
            this.dialogService.show('Please, select at least one Source to delete', ['OK']);
            return;
        }
        var dialog = this.dialog.open(NotificationConfirmationsComponent, {
            data: {
                messageHTML: DELETE_SOURCE_CONFIRM_MESSAGE
            }
        });
        dialog.afterClosed().subscribe(function (j) {
            try {
                if (j.status) {
                    _this.medicalSourcesService.deleteSources(_this.deleteOthersArray).subscribe(function () {
                        _this.deleteOthersArray = [];
                        _this.loadOthers();
                    });
                }
            }
            catch (e) {
            }
        });
    };
    return OthersComponent;
}());
export { OthersComponent };
