import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MedicalService } from '../../../../../@core/medical.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from "../../../../../@core/navigation.service";
import { GlobalSaveService } from "../../../../../@core/global-save.service";
import { UserInfoService } from "../../../../../@core/user-info.service";
import { NotificationConfirmationsComponent } from "../../../../notification/confirmations/notification-confirmations.component";
import { MedicalSourcesService } from "../../../../../@core/medical-sources.service";
import { DoctorFormNewComponent } from "./doctor-form-new/doctor-form-new.component";
import { DATE_FORMAT, DEFAULT_DATE } from "../../../../../@config/dateFormat";
import { MDEDICAL_TREATING_SOURCES_STATUSES } from "../../../../../@config/status/medical-treating-sources";
import { LoadingService } from "../../../../../../@util/loading/loading.service";
import { DialogService } from "../../../../../../@util/dialog/dialog.service";
import { DELETE_SOURCE_CONFIRM_MESSAGE } from "../medical-treating-sources.config";
var DoctorsComponent = /** @class */ (function () {
    function DoctorsComponent(medicalService, medicalSourcesService, dialog, route, globalSaveService, router, dialogService, userInfoService, loadingService, navService) {
        var _this = this;
        this.medicalService = medicalService;
        this.medicalSourcesService = medicalSourcesService;
        this.dialog = dialog;
        this.route = route;
        this.globalSaveService = globalSaveService;
        this.router = router;
        this.dialogService = dialogService;
        this.userInfoService = userInfoService;
        this.loadingService = loadingService;
        this.navService = navService;
        this.DATE_FORMAT = DATE_FORMAT;
        this.DEFAULT_DATE = DEFAULT_DATE;
        this.title = 'Doctors';
        this.closeForm = new EventEmitter();
        this.canScrollLeft = false;
        this.canScrollRight = true;
        this.medicalTreatingSourcesStatuses = MDEDICAL_TREATING_SOURCES_STATUSES;
        this.deleteDoctorsArray = [];
        this.dataSource = new MatTableDataSource([]);
        this.sub = navService.back().subscribe(function (j) { return _this.goBack(); });
    }
    DoctorsComponent.prototype.ngOnDestroy = function () {
        this.sub.unsubscribe();
    };
    DoctorsComponent.prototype.goBack = function () {
        this.navService.navigate(['/claimants'], ["" + this.route.snapshot.paramMap.get('sslgId')]);
    };
    DoctorsComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.sslgId = this.route.snapshot.parent.paramMap.get('sslgId');
                this.loadDoctors();
                return [2 /*return*/];
            });
        });
    };
    DoctorsComponent.prototype.loadDoctors = function () {
        var _this = this;
        this.dataSource.paginator = this.paginator;
        this.medicalService.getAllDoctors(this.sslgId).subscribe(function (data) {
            _this.doctors = data;
            _this.doctors.sort(function (a, b) {
                var aNext = a.visits && a.visits.nextVisit ? a.visits.nextVisit : null;
                var bNext = b.visits && b.visits.nextVisit ? b.visits.nextVisit : null;
                if (aNext < bNext) {
                    return 1;
                }
                if (aNext > bNext) {
                    return -1;
                }
                return 0;
            });
            _this.loadData();
        });
    };
    DoctorsComponent.prototype.loadData = function () {
        var _this = this;
        this.dataSource.data = this.doctors;
        console.log(this.doctors);
        this.data = this.dataSource.connect();
        if (this.route.snapshot.queryParamMap.get('page')) {
            this.paginator.pageIndex = +this.route.snapshot.queryParamMap.get('page');
            this.data.next(this.dataSource._pageData(this.doctors));
        }
        var popupNew = this.route.snapshot.queryParamMap.get('popup');
        var popupNewFrom = this.route.snapshot.queryParamMap.get('from');
        if (popupNew) {
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: { popup: null },
                queryParamsHandling: 'merge',
            });
            setTimeout(function () {
                _this.addDoctor(popupNewFrom);
            });
        }
    };
    DoctorsComponent.prototype.doctorInfo = function (visit) {
        var url = this.getUrl();
        this.router.navigate([url + '/' + visit.id + '/info'], {
            queryParams: {
                returnUrl: url,
                page: this.paginator.pageIndex
            }
        });
    };
    DoctorsComponent.prototype.getUrl = function () {
        return this.router.url.indexOf('?') !== -1 ?
            this.router.url.substring(0, this.router.url.indexOf('?')) : this.router.url;
    };
    DoctorsComponent.prototype.addDoctor = function (from) {
        //const url = this.getUrl();
        //this.router.navigate([url + '/new'], {queryParams: {returnUrl: url, page: this.paginator.pageIndex}});
        var _this = this;
        var dialogRef = this.dialog.open(DoctorFormNewComponent, {
            data: {
                sslgId: this.sslgId,
                from: from
            },
            panelClass: ['mobile-popup', 'with-shadow', 'popup-scrollable'],
            // panelClass: ['mobile-popup', 'with-shadow'],
            backdropClass: 'mobile-popup__overlay',
            disableClose: true
        });
        dialogRef.componentInstance.updateDoctorsList.subscribe(function (result) {
            _this.loadDoctors();
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                if (result.cmd) {
                    if (result.cmd == 'addVisit') {
                        _this.router.navigate(['/claimants/medical/' + _this.sslgId + '/visit-summaries/new'], {
                            queryParams: {
                                returnUrl: _this.route.snapshot.queryParams['returnUrl'],
                                catalogItemId: result.data.id,
                                type: 'DOCTOR',
                            }
                        });
                    }
                    else {
                        _this.loadDoctors();
                    }
                }
                else if (result == 'new') {
                    _this.loadDoctors();
                }
            }
        });
        document.querySelectorAll('.mat-drawer-content').forEach(function (el) {
            el.style.overflow = 'hidden';
        });
    };
    DoctorsComponent.prototype.isClaimant = function () {
        if (this.userInfoService.getCurrentUserRole() == 'CLIENT') {
            return true;
        }
        return false;
    };
    DoctorsComponent.prototype.isSystem = function () {
        return this.userInfoService.getCurrentUserNameIsSystem();
    };
    DoctorsComponent.prototype.isExecutive = function () {
        if (this.userInfoService.getCurrentUserRole() == 'EXECUTIVE_STAFF') {
            return true;
        }
        return false;
    };
    DoctorsComponent.prototype.getFirstSeen = function (visits) {
        if (visits) {
            if (new Date(visits.firstSeen).toUTCString() !== new Date('1969-12-31 00:00:00').toUTCString()) {
                return this.getDate(visits.firstSeen);
            }
        }
        return "";
    };
    DoctorsComponent.prototype.getLastSeen = function (visits) {
        if (visits) {
            if (new Date(visits.lastSeen).toUTCString() !== new Date('1969-12-31 00:00:00').toUTCString()) {
                return this.getDate(visits.lastSeen);
            }
        }
        return "";
    };
    DoctorsComponent.prototype.getNextVisit = function (visits) {
        if (visits) {
            if (new Date(visits.nextVisit).toUTCString() !== new Date('1969-12-31 00:00:00').toUTCString()) {
                return this.getDate(visits.nextVisit);
            }
        }
        return "";
    };
    DoctorsComponent.prototype.getDate = function (value) {
        if (value === this.DEFAULT_DATE) {
            return '';
        }
        var datePipe = new DatePipe("en-US");
        if (value) {
            value = datePipe.transform(value.replace(/ /g, "T"), this.DATE_FORMAT.defaultPipe);
        }
        return value;
    };
    // delete(item,index){
    // 	let dialog = this.dialog.open(NotificationConfirmationsComponent,{
    // 		data:{
    // 			message: "Are you sure you want to delete the source?"
    // 		}
    // 	});
    // 	dialog.afterClosed().subscribe(j => {
    // 		try {
    // 			if (j.status) {
    // 				this.medicalSourcesService.deleteSource(this.sslgId, item.id).subscribe(() => {
    // 					let itemIndex = index + this.paginator.pageSize * this.paginator.pageIndex;
    // 					this.doctors.splice(itemIndex, 1);
    // 					this.loadData();
    // 				});
    // 			}
    // 		} catch (e) {
    //
    // 		}
    // 	});
    // }
    DoctorsComponent.prototype.tableScrolled = function ($event) {
        var scrollLeft = $event.target.scrollLeft;
        var scrollWidth = $event.target.scrollWidth;
        var offsetWidth = $event.target.offsetWidth;
        if (scrollLeft > 0) {
            this.canScrollLeft = true;
        }
        else {
            this.canScrollLeft = false;
        }
        if (scrollWidth - scrollLeft > offsetWidth) {
            this.canScrollRight = true;
        }
        else {
            this.canScrollRight = false;
        }
    };
    DoctorsComponent.prototype.getTableHeadClass = function () {
        // console.log(this.globalSaveService.getStatusTrue())
        // if (this.isSystem()) {
        // 	return 'i-btn-2';
        // }
        return 'i-btn-1';
        // if (this.globalSaveService.getStatusTrue()) {
        // 	if (this.isSystem()) {
        // 		return 'i-btn-2';
        // 	}
        // 	return 'i-btn-1';
        // } else {
        // 	if (this.isSystem()) {
        // 		return 'i-btn-2';
        // 	}
        // 	return 'i-btn-0';
        // }
    };
    DoctorsComponent.prototype.onMedicalTreatingSourcesStatusChange = function ($event, doctor, select) {
        var _this = this;
        var newValue = $event.value;
        this.medicalSourcesService.changeStatusSource(this.sslgId, doctor.id, newValue).subscribe(function (data) {
            if (data[status] === false) {
                _this.loadingService.showError([data['message']]);
                select.value = doctor.catalogStatus;
            }
            else {
                _this.loadData();
            }
        }, function () {
            select.value = doctor.catalogStatus;
        });
    };
    DoctorsComponent.prototype.getMedicalTreatingSourcesCatalogStatus = function (doctor) {
        // if (doctor.catalogStatus) {
        return doctor.catalogStatus;
        // }
        //return 0;
    };
    DoctorsComponent.prototype.toggleDeleteDoctorsArray = function (e, id) {
        var _this = this;
        if (e.checked) {
            var elExist_1;
            this.deleteDoctorsArray.forEach(function (el) {
                if (el.id === id) {
                    elExist_1 = el;
                }
            });
            if (!elExist_1) {
                this.deleteDoctorsArray.push({
                    id: id,
                    sslgId: this.sslgId
                });
            }
        }
        else {
            this.deleteDoctorsArray.forEach(function (el, index) {
                if (el.id === id) {
                    _this.deleteDoctorsArray.splice(index, 1);
                }
            });
        }
    };
    DoctorsComponent.prototype.deleteDoctors = function () {
        var _this = this;
        if (!this.deleteDoctorsArray.length) {
            this.dialogService.show('Please, select at least one Doctor to delete', ['OK']);
            return;
        }
        var dialog = this.dialog.open(NotificationConfirmationsComponent, {
            data: {
                messageHTML: DELETE_SOURCE_CONFIRM_MESSAGE
            }
        });
        dialog.afterClosed().subscribe(function (j) {
            try {
                if (j.status) {
                    _this.medicalSourcesService.deleteSources(_this.deleteDoctorsArray).subscribe(function () {
                        _this.deleteDoctorsArray = [];
                        _this.loadDoctors();
                    });
                }
            }
            catch (e) {
            }
        });
    };
    return DoctorsComponent;
}());
export { DoctorsComponent };
