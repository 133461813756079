import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { DynamicFormService } from '@ng-dynamic-forms/core';
import { DATE_MANUAL, RADIO_USER_STATE, STRING_FIELD } from "../../../../@util/dynamic-form/dynamic-controlls";
import { FormValueBuilder } from "../../partial/forms/form-tamplate";
import { DynamicForm } from "../../../../@util/dynamic-form/dynamic-form";
import { DynamicCheckboxModel, DynamicFormArrayModel as DynamicFormArray, DynamicFormGroupModel, DynamicTextAreaModel } from "../../../../@util/ui-material/form-controls";
import { GlobalSaveService } from "../../../@core/global-save.service";
import { UserInfoService } from "../../../@core/user-info.service";
import { MEDICAL_TESTS_MODE } from "./partial-medical-tests.config";
var PartialMedicalTestsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PartialMedicalTestsComponent, _super);
    function PartialMedicalTestsComponent(formService, globalSaveService, userInfoService) {
        var _this = _super.call(this) || this;
        _this.formService = formService;
        _this.globalSaveService = globalSaveService;
        _this.userInfoService = userInfoService;
        // @Input()
        // canEdit;
        // @Input()
        // medicalTest;
        _this.isHospital = false;
        _this.backForm = new EventEmitter();
        _this.nextForm = new EventEmitter();
        _this.closeForm = new EventEmitter();
        _this.saveForm = new EventEmitter();
        _this.title = 'Medical Tests';
        _this.MEDICAL_TESTS_MODE = MEDICAL_TESTS_MODE;
        _this.formLayout = {
            'treatment': {
                element: {
                    host: 'material-form-group longer-centered',
                    container: 'top-label form-element_hide-label',
                }
            },
            'showMedicalTests': {
                element: {
                    container: 'top-label'
                }
            },
            form: {
                element: {
                    label: 'form-label form-label_full-width form_mb',
                    control: 'form-row is-hidden'
                },
                grid: {
                    container: 'row-mb'
                }
            },
            col1rowLabelTest: {
                element: {
                    host: 'form-element form-col',
                    control: 'sm-col',
                    label: 'form-label form-label_full-width form_mb'
                },
            },
            col1rowLabelBodyPart: {
                element: {
                    host: 'form-element form-col',
                    control: 'sm-col',
                    label: 'form-label form-label_full-width form_mb'
                },
            },
            col1rowLabelDate: {
                element: {
                    host: 'form-element form-col',
                    control: 'sm-col',
                    label: 'form-label form-label_full-width form_mb'
                },
            },
            col1: {
                element: {
                    host: 'form-element',
                    control: '',
                    children: 'col-children col1-children',
                },
            },
            col2rowLabelTest: {
                element: {
                    host: 'form-element form-col',
                    control: 'sm-col',
                    label: 'form-label form-label_full-width form_mb'
                },
            },
            col2rowLabelDate: {
                element: {
                    host: 'form-element form-col',
                    control: 'sm-col',
                    label: 'form-label form-label_full-width form_mb'
                },
            },
            col2: {
                element: {
                    host: 'form-element',
                    control: '',
                    children: 'col-children col2-children',
                }
            },
            col1Test: {
                element: {
                    host: 'form-element form-col',
                    control: 'sm-col',
                    label: 'form-label form-label_full-width form_mb'
                }
            },
            col1Body: {
                element: {
                    host: 'form-element form-col',
                    control: 'sm-col',
                    label: 'form-label form-label_full-width form_mb'
                }
            },
            col1Date: {
                element: {
                    host: 'form-element form-col',
                    control: 'sm-col',
                    label: 'form-label form-label_full-width form_mb'
                }
            },
            col2Test: {
                element: {
                    host: 'form-element form-col',
                    control: 'md-col',
                    label: 'form-label form-label_full-width form_mb'
                }
            },
            col2Body: {
                element: {
                    host: 'form-element form-col',
                    control: 'md-col',
                    label: 'form-label form-label_full-width form_mb'
                }
            },
            col2Date: {
                element: {
                    host: 'form-element form-col',
                    control: 'md-col',
                    label: 'form-label form-label_full-width form_mb'
                }
            },
            biopsyDescription: {
                element: {
                    host: 'form-element form-element_hide-label'
                }
            },
            mriDescription: {
                element: {
                    host: 'form-element form-element_hide-label'
                }
            },
            xRayDescription: {
                element: {
                    host: 'form-element form-element_hide-label'
                }
            },
            otherDescription: {
                element: {
                    host: 'form-element form-element_hide-label'
                }
            },
        };
        _this.medicalTestsWithBodyPart = [
            {
                name: 'X Ray',
                shortName: 'xRay',
            },
            {
                name: 'MRI',
                shortName: 'mri',
            },
            {
                name: 'CT Scan',
                shortName: 'ctScan',
            },
            {
                name: 'Ultra Sound',
                shortName: 'ultraSound',
            },
            {
                name: 'Biopsy',
                shortName: 'biopsy',
            },
            {
                name: 'Vascular',
                shortName: 'vascular',
            },
        ];
        _this.medicalTestsWithOUTBodyPart = [
            {
                name: 'Sleep Study',
                shortName: 'sleepStudy',
            },
            {
                name: 'Breathing',
                shortName: 'breathing',
            },
            {
                name: 'EKG (Heart Test)',
                shortName: 'ekg',
            },
            {
                name: 'ECG (Brain Wave)',
                shortName: 'ecg',
            },
            {
                name: 'Treadmill',
                shortName: 'treadmill',
            },
            {
                name: 'Vision',
                shortName: 'vision',
            },
            {
                name: 'BLOOD',
                shortName: 'blood',
            },
            {
                name: 'HIV',
                shortName: 'hiv',
            },
            {
                name: 'Endoscopy',
                shortName: 'endoscopy',
            },
            {
                name: 'Neuropsych Exam',
                shortName: 'neuropsychExam',
            },
            {
                name: 'Neurocognitive Exam',
                shortName: 'neurocognitiveExam',
            },
        ];
        _this.form = {
            treatment: DynamicTextAreaModel({ id: 'treatment', maxLength: 150, hidden: true, editableFor: ["EXECUTIVE_STAFF", "CLIENT"] }),
            showMedicalTests: RADIO_USER_STATE({ id: 'showMedicalTests', options: [{ label: 'YES', value: true }, { label: 'NO', value: false }], editableFor: ["EXECUTIVE_STAFF", "CLIENT"] })
        };
        _this.isTestsVisible = false;
        _this.medicalTestsWithBodyPart.forEach(function (el) {
            _this.form[el.shortName] = DynamicCheckboxModel({ id: el.shortName, label: el.name, editableFor: ["EXECUTIVE_STAFF", "CLIENT"], });
            _this.form[el.shortName + 'BodyPart'] = STRING_FIELD({ id: el.shortName + 'BodyPart', placeholder: 'Body Part', disabled: true, maxLength: 20, editableFor: ['EXECUTIVE_STAFF', "CLIENT"] });
            _this.form[el.shortName + 'Date'] = DATE_MANUAL({ id: el.shortName + 'Date', disabled: true, editableFor: ['EXECUTIVE_STAFF', "CLIENT"] });
            // xRay: DynamicCheckboxModel({id: 'xRay', label: 'X-RAY',editableFor: ["EXECUTIVE_STAFF", "CLIENT"],}),
            // 	xRayBodyPart: STRING_FIELD({id: 'xRayBodyPart', maxLength: 20, editableFor: ['EXECUTIVE_STAFF', "CLIENT"]}),
            // 	xRayDate: DATE_MANUAL({id: 'xRayDate', editableFor: ['EXECUTIVE_STAFF', "CLIENT"]}),
        });
        _this.medicalTestsWithOUTBodyPart.forEach(function (el) {
            _this.form[el.shortName] = DynamicCheckboxModel({ id: el.shortName, label: el.name, editableFor: ["EXECUTIVE_STAFF", "CLIENT"], });
            _this.form[el.shortName + 'Date'] = DATE_MANUAL({ id: el.shortName + 'Date', disabled: true, editableFor: ['EXECUTIVE_STAFF', "CLIENT"] });
        });
        // this.formTemplate = {
        // 	treatment: this.form.treatment,
        // 	showMedicalTests: this.form.showMedicalTests,
        // 	form: DynamicFormGroupModel({
        // 		id: 'form',
        // 		group: [
        // 			DynamicFormGroupModel({
        // 				id: 'col1',
        // 				group: [
        // 					DynamicFormGroupModel({
        // 						label: 'Test',
        // 						id: 'col1Test',
        // 						group: [], editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
        // 					}),
        // 					DynamicFormGroupModel({
        // 						label: 'Body Part',
        // 						id: 'col1Body', group: [], editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
        // 					}),
        // 					DynamicFormGroupModel({
        // 						label: 'Date',
        // 						id: 'col1Date', group: [], editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
        // 					}),
        // 				], editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
        // 			}),
        // 			DynamicFormGroupModel({
        // 				id: 'col2',
        // 				group: [
        // 					DynamicFormGroupModel({
        // 						label: 'Test',
        // 						id: 'col2Test',
        // 						group: [], editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
        // 					}),
        // 					DynamicFormGroupModel({
        // 						label: 'Date',
        // 						id: 'col2Date', group: [], editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
        // 					}),
        // 				], editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
        // 			}),
        // 		], editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
        // 	})
        // };
        _this.formTemplate = {
            treatment: _this.form.treatment,
            showMedicalTests: _this.form.showMedicalTests,
            form: DynamicFormGroupModel({
                id: 'form',
                group: [
                    DynamicFormGroupModel({
                        id: 'col1',
                        group: [], editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
                    }),
                    DynamicFormGroupModel({
                        id: 'col2',
                        group: [], editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
                    }),
                ], editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
            }),
        };
        _this.formTemplate.form.group[0].group[0] = DynamicFormGroupModel({
            // label: 'Test',
            id: "col1rowLabels",
            group: [
                DynamicFormGroupModel({
                    label: 'Test',
                    id: "col1rowLabelTest"
                }),
                DynamicFormGroupModel({
                    label: 'Body Part',
                    id: "col1rowLabelBodyPart",
                }),
                DynamicFormGroupModel({
                    label: 'Date <span>(mm/dd/yyyy)</span>',
                    id: "col1rowLabelDate",
                }),
            ],
        });
        _this.medicalTestsWithBodyPart.forEach(function (el, index) {
            _this.formTemplate.form.group[0].group[index + 1] = DynamicFormGroupModel({
                id: "col1row" + index,
                group: [
                    _this.form[el.shortName],
                    _this.form[el.shortName + 'BodyPart'],
                    _this.form[el.shortName + 'Date']
                ], editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
            });
            el.formId = "col1row" + index;
            // this.formTemplate.form.group[0].group[0].group.push(this.form[el.shortName]);
            // this.formTemplate.form.group[0].group[1].group.push(this.form[el.shortName + 'BodyPart']);
            // this.formTemplate.form.group[0].group[2].group.push(this.form[el.shortName + 'Date']);
        });
        _this.formTemplate.form.group[0].group.push(DynamicFormArray({
            id: 'others',
            initialCount: 1,
            groupFactory: function () {
                return [
                    DynamicCheckboxModel({ id: 'other', label: 'Other', editableFor: ["EXECUTIVE_STAFF", "CLIENT"] }),
                    STRING_FIELD({ id: 'otherBodyPart', placeholder: 'Body Part', disabled: true, maxLength: 20, editableFor: ['EXECUTIVE_STAFF', "CLIENT"] }),
                    DATE_MANUAL({ id: 'otherDate', disabled: true, editableFor: ['EXECUTIVE_STAFF', "CLIENT"] }),
                ];
            }, editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
        }));
        // this.formTemplate.form.group[0].group[this.formTemplate.form.group[0].group.length + 1] = DynamicFormArray({
        // 	id: 'others',
        // 	initialCount: 1,
        // 	groupFactory: () => {
        // 		return [
        // 			DynamicCheckboxModel({id: 'other', label: 'Other', editableFor: ["EXECUTIVE_STAFF", "CLIENT"]}),
        // 			STRING_FIELD({id: 'otherBodyPart', placeholder: 'Body Part', disabled: true, maxLength: 20, editableFor: ['EXECUTIVE_STAFF', "CLIENT"]}),
        // 			DATE_MANUAL({id: 'otherDate', disabled: true,  editableFor: ['EXECUTIVE_STAFF', "CLIENT"]}),
        // 		];
        // 	},editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
        // });
        _this.formTemplate.form.group[1].group[0] = DynamicFormGroupModel({
            // label: 'Test',
            id: "col2rowLabels",
            group: [
                DynamicFormGroupModel({
                    label: 'Test',
                    id: "col2rowLabelTest"
                }),
                DynamicFormGroupModel({
                    label: 'Date <span>(mm/dd/yyyy)</span>',
                    id: "col2rowLabelDate",
                }),
            ],
        });
        _this.medicalTestsWithOUTBodyPart.forEach(function (el, index) {
            _this.formTemplate.form.group[1].group[index + 1] = DynamicFormGroupModel({
                id: "col2row" + index,
                group: [
                    _this.form[el.shortName],
                    _this.form[el.shortName + 'Date']
                ], editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
            });
            el.formId = "col2row" + index;
            // this.formTemplate.form.group[1].group[0].group.push(this.form[el.shortName]);
            // this.formTemplate.form.group[1].group[1].group.push(this.form[el.shortName + 'Date']);
        });
        _this.formModel = FormValueBuilder.buildFormModel(_this.formTemplate);
        _this.formGroup = formService.createFormGroup(_this.formModel);
        _this.arrayControl = _this.formGroup.controls.form['controls'].col1.controls['others'];
        _this.arrayModel = _this.formService.findById('others', _this.formModel);
        return _this;
    }
    Object.defineProperty(PartialMedicalTestsComponent.prototype, "medicalTest", {
        get: function () {
            return this._medicalTest;
        },
        set: function (medicalTest) {
            this._medicalTest = medicalTest;
            this.setMedicalTest(medicalTest);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PartialMedicalTestsComponent.prototype, "canEdit", {
        get: function () {
            return this._canEdit;
        },
        set: function (canEdit) {
            this._canEdit = canEdit;
        },
        enumerable: true,
        configurable: true
    });
    PartialMedicalTestsComponent.prototype.ngOnInit = function () {
        var _this = this;
        FormValueBuilder.setData(this.medicalTest, this.form);
        FormValueBuilder.resetToDefault(this.form);
        this.formGroup.valueChanges.subscribe(function (j) {
            _this.formStateChanged.emit({ key: _this.formKey, value: FormValueBuilder.isDirty(_this.form) });
        });
        this.form.showMedicalTests.valueUpdates.subscribe(function (j) {
            if (j === false) {
                _this.nextMedicalTest();
            }
            _this.isTestsVisible = !!j;
            _this.isTestsVisible ? document.querySelector('#form').classList.remove('is-hidden') : document.querySelector('#form').classList.add('is-hidden');
        });
        if (this.isHospital) {
            this.formTemplate.treatment.hidden = true;
        }
        // setTimeout(() => {
        // 	this.formGroup.controls.showMedicalTests.setValue(false);
        // });
    };
    PartialMedicalTestsComponent.prototype.ngOnChanges = function (changes) {
        if (this.isHospital) {
            this.formTemplate.treatment.hidden = true;
        }
        else {
            this.formTemplate.treatment.hidden = false;
        }
    };
    PartialMedicalTestsComponent.prototype.onFocus = function ($event) {
    };
    PartialMedicalTestsComponent.prototype.onBlur = function ($event) {
    };
    PartialMedicalTestsComponent.prototype.onChange = function ($event) {
        var _this = this;
        if ($event.model.id === 'other') {
            if ($event.model.value) {
                setTimeout(function () {
                    $event.context.group[1].disabledUpdates.next(false);
                    $event.context.group[2].disabledUpdates.next(false);
                });
            }
            else {
                setTimeout(function () {
                    $event.context.group[1].disabledUpdates.next(true);
                    $event.context.group[2].disabledUpdates.next(true);
                });
            }
        }
        this.medicalTestsWithBodyPart.forEach(function (el) {
            if (_this.form[el.shortName].id === $event.model.id) {
                if (_this.form[el.shortName].value) {
                    _this.form[el.shortName + 'BodyPart'].disabled = false;
                    _this.form[el.shortName + 'Date'].disabled = false;
                    _this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName + 'BodyPart'].enable();
                    _this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName + 'Date'].enable();
                }
                else {
                    _this.form[el.shortName + 'BodyPart'].disabled = true;
                    _this.form[el.shortName + 'Date'].disabled = true;
                    _this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName + 'BodyPart'].disable();
                    _this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName + 'Date'].disable();
                    if (!_this.isEditingMode()) {
                        _this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName + 'Date'].setValue(null);
                        _this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName + 'BodyPart'].setValue(null);
                    }
                }
            }
        });
        this.medicalTestsWithOUTBodyPart.forEach(function (el) {
            if (_this.form[el.shortName].id === $event.model.id) {
                if (_this.form[el.shortName].value) {
                    _this.form[el.shortName + 'Date'].disabled = false;
                    _this.formGroup.controls.form['controls'].col2.controls[el.formId].controls[el.shortName + 'Date'].enable();
                }
                else {
                    _this.form[el.shortName + 'Date'].disabled = true;
                    _this.formGroup.controls.form['controls'].col2.controls[el.formId].controls[el.shortName + 'Date'].disable();
                    if (!_this.isEditingMode()) {
                        _this.formGroup.controls.form['controls'].col2.controls[el.formId].controls[el.shortName + 'Date'].setValue(null);
                    }
                }
            }
        });
    };
    PartialMedicalTestsComponent.prototype.setMedicalTest = function (medicalTest) {
        var _this = this;
        var treatment = medicalTest && medicalTest.medicalTestInfo ? medicalTest.medicalTestInfo.treatment : null;
        var showTests = medicalTest && medicalTest.medicalTestInfo ? medicalTest.medicalTestInfo.tests : false;
        var medicalTestsList = medicalTest && medicalTest.medicalTestsList ? medicalTest.medicalTestsList : [];
        var medicalTestsListOthers = medicalTestsList.filter(function (el) { return el.shortName.includes('other'); });
        console.log(medicalTestsList, medicalTestsListOthers);
        medicalTestsListOthers.forEach(function (el, index) {
            if (index) {
                _this.insertOther();
            }
            setTimeout(function () {
                _this.formGroup.controls.form['controls'].col1.controls['others'].controls[index].enable();
                _this.formGroup.controls.form['controls'].col1.controls['others'].controls[index].controls['other'].setValue(true);
                _this.formGroup.controls.form['controls'].col1.controls['others'].controls[index].controls['otherBodyPart'].setValue(el.description);
                _this.formGroup.controls.form['controls'].col1.controls['others'].controls[index].controls['otherDate'].setValue(_this.getTestDate(el.date));
            });
        });
        // this.formTemplate.form.group[0].group.push(DynamicFormArray({
        // 	id: 'others',
        // 	initialCount: 1,
        // 	groupFactory: () => {
        // 		return [
        // 			DynamicCheckboxModel({id: 'other', label: 'Other', editableFor: ["EXECUTIVE_STAFF", "CLIENT"]}),
        // 			STRING_FIELD({id: 'otherBodyPart', placeholder: 'Body Part', disabled: true, maxLength: 20, editableFor: ['EXECUTIVE_STAFF', "CLIENT"]}),
        // 			DATE_MANUAL({id: 'otherDate', disabled: true,  editableFor: ['EXECUTIVE_STAFF', "CLIENT"]}),
        // 		];
        // 	},editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
        // }));
        this.formGroup.controls.treatment.setValue(treatment);
        this.formGroup.controls.showMedicalTests.setValue(showTests);
        if (!this.canEdit) {
            this.formGroup.disable();
        }
        else {
            this.formGroup.controls.treatment.enable();
            this.formGroup.controls.showMedicalTests.enable();
        }
        medicalTestsList.forEach(function (test) {
            _this.medicalTestsWithBodyPart.forEach(function (el) {
                if (_this.canEdit) {
                    _this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName].enable();
                }
                if (el.shortName === test.shortName) {
                    _this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName].setValue(true);
                    _this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName + 'BodyPart'].setValue(test.description);
                    _this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName + 'Date'].setValue(_this.getTestDate(test.date));
                    if (_this.canEdit) {
                        _this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName].enable();
                        _this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName + 'BodyPart'].enable();
                        _this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName + 'Date'].enable();
                    }
                }
            });
            _this.medicalTestsWithOUTBodyPart.forEach(function (el) {
                if (_this.canEdit) {
                    _this.formGroup.controls.form['controls'].col2.controls[el.formId].controls[el.shortName].enable();
                }
                if (el.shortName === test.shortName) {
                    _this.formGroup.controls.form['controls'].col2.controls[el.formId].controls[el.shortName].setValue(true);
                    _this.formGroup.controls.form['controls'].col2.controls[el.formId].controls[el.shortName + 'Date'].setValue(_this.getTestDate(test.date));
                    if (_this.canEdit) {
                        _this.formGroup.controls.form['controls'].col2.controls[el.formId].controls[el.shortName].enable();
                        _this.formGroup.controls.form['controls'].col2.controls[el.formId].controls[el.shortName + 'Date'].enable();
                    }
                }
            });
        });
    };
    PartialMedicalTestsComponent.prototype.getTestDate = function (date) {
        // const dateObject = new Date(date);
        // 		// let isDate = String(dateObject.getDate());
        // 		// if (isDate.length === 1) {
        // 		// 	isDate = '0' + isDate;
        // 		// }
        // 		// let isMonth = String(dateObject.getMonth() + 1);
        // 		// if (isMonth.length === 1) {
        // 		// 	isMonth = '0' + isMonth;
        // 		// }
        // 		// const isYear = String(dateObject.getFullYear());
        var isDate = date.slice(8, 10);
        var isMonth = date.slice(5, 7);
        var isYear = date.slice(0, 4);
        return isDate + isMonth + isYear;
    };
    PartialMedicalTestsComponent.prototype.saveMedicalTest = function () {
        var _this = this;
        FormValueBuilder.setControlsToched(this.formGroup);
        if (!this.formGroup.valid) {
            return;
        }
        var sendFormData = this.getSendForm();
        setTimeout(function () {
            _this.saveForm.emit(sendFormData);
        });
        // FormValueBuilder.resetToDefault(this.form);
    };
    PartialMedicalTestsComponent.prototype.getSendForm = function () {
        var _this = this;
        var formData = FormValueBuilder.getData(this.form);
        var sendFormData = {};
        var others = this.formGroup.controls.form['controls'].col1.controls['others'].value ? this.formGroup.controls.form['controls'].col1.controls['others'].value : [];
        others = others.filter(function (el) { return el.other && (el.other !== 'false'); });
        var newFormGroupOthers = others.map(function (j, index) {
            if (j.other) {
                return {
                    shortName: "other" + (index + 1),
                    description: j.otherBodyPart,
                    date: _this.formatDate(j.otherDate),
                };
            }
        });
        sendFormData.treatment = formData.treatment;
        sendFormData.showMedicalTests = formData.showMedicalTests;
        sendFormData.medicalTestsList = [];
        tslib_1.__spread(this.medicalTestsWithBodyPart, this.medicalTestsWithOUTBodyPart).forEach(function (el) {
            if (el.shortName && formData[el.shortName]) {
                sendFormData.medicalTestsList.push({
                    shortName: el.shortName,
                    description: formData[el.shortName + 'BodyPart'],
                    date: _this.formatDate(formData[el.shortName + 'Date']),
                });
            }
        });
        sendFormData.medicalTestsList = tslib_1.__spread(sendFormData.medicalTestsList, newFormGroupOthers);
        return sendFormData;
    };
    PartialMedicalTestsComponent.prototype.formatDate = function (date) {
        if (date) {
            try {
                var dayNum = date.slice(2, 4);
                var month = date.slice(0, 2);
                var year = date.slice(4).length === 2 ? "20" + date.slice(4) : date.slice(4);
                return year + "-" + month + "-" + dayNum + " 00:00:00";
            }
            catch (e) {
                console.log('ERROR date incorrect');
                return null;
            }
        }
        return null;
    };
    PartialMedicalTestsComponent.prototype.backMedicalTest = function () {
        this.backForm.emit();
    };
    PartialMedicalTestsComponent.prototype.nextMedicalTest = function () {
        FormValueBuilder.setControlsToched(this.formGroup);
        if (!this.formGroup.valid) {
            return;
        }
        var sendFormData = this.getSendForm();
        this.nextForm.emit(sendFormData);
    };
    PartialMedicalTestsComponent.prototype.closeMedicalTest = function () {
        this.closeForm.emit();
    };
    PartialMedicalTestsComponent.prototype.ngOnDestroy = function () {
        this.formStateChanged.next({ value: false, key: this.formKey });
    };
    PartialMedicalTestsComponent.prototype.isClaimant = function () {
        if (this.userInfoService.getCurrentUserRole() == 'CLIENT') {
            return true;
        }
        return false;
    };
    PartialMedicalTestsComponent.prototype.isEditingMode = function () {
        return this.mode === this.MEDICAL_TESTS_MODE.edit;
    };
    PartialMedicalTestsComponent.prototype.isCreationMode = function () {
        return this.mode === this.MEDICAL_TESTS_MODE.create;
    };
    PartialMedicalTestsComponent.prototype.isCreationVisitMode = function () {
        return this.mode === this.MEDICAL_TESTS_MODE.createVisit;
    };
    PartialMedicalTestsComponent.prototype.insertOther = function () {
        var _this = this;
        this.formService.addFormArrayGroup(this.arrayControl, this.arrayModel);
        setTimeout(function () {
            _this.arrayModel.groups[_this.arrayModel.groups.length - 1].group[5].valueUpdates.next(_this.prescribedBy);
            FormValueBuilder.resetToDefault(_this.arrayModel.groups[_this.arrayModel.groups.length - 1].group);
        });
    };
    PartialMedicalTestsComponent.prototype.isRemoveHidden = function () {
        return this.arrayModel.groups.length < 2;
    };
    PartialMedicalTestsComponent.prototype.canRemove = function () {
        return this.arrayModel.groups.length > 1;
    };
    PartialMedicalTestsComponent.prototype.removeOther = function (index) {
        if (this.canRemove()) {
            this.formService.removeFormArrayGroup(index, this.arrayControl, this.arrayModel);
        }
    };
    PartialMedicalTestsComponent.prototype.getButtonType = function () {
        return this.canRemove() ? 'warning' : 'disabled';
    };
    PartialMedicalTestsComponent.prototype.setPrescribedValue = function () {
        var _this = this;
        this.arrayModel.groups.forEach(function (item) {
            item.group[5].valueUpdates.next(_this.prescribedBy);
            FormValueBuilder.resetToDefault(item.group[5]);
        });
    };
    return PartialMedicalTestsComponent;
}(DynamicForm));
export { PartialMedicalTestsComponent };
