import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { DynamicFormArrayModel, DynamicFormControlLayout, DynamicFormControlModel, DynamicFormService } from "@ng-dynamic-forms/core";
import { ADDRESS, CYTY, DATE_PICKER, EXT, PHONE, PRESS, RADIO_USER_STATE, STATE_SELECT, STRING_FIELD, ZIP } from "../../../../../../../@util/dynamic-form/dynamic-controlls";
import { DynamicFormArrayModel as DunamicFormArray, DynamicFormGroupModel, DynamicRadioGroupModel } from "../../../../../../../@util/ui-material/form-controls";
import { DoctorType } from "../../../../../../@models/medical/doctor-item";
import { State } from "../../../../../../test-new-stepper-form/test-form/on-changes";
import { MedicalService } from "../../../../../../@core/medical.service";
import { AddressService } from "../../../../../../@core/address.service";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { UserInfoService } from "../../../../../../@core/user-info.service";
import { DateService } from "../../../../../../@core/functions/date.service";
import { DialogService } from "../../../../../../../@util/dialog/dialog.service";
import { NavigationService } from "../../../../../../@core/navigation.service";
import { FormValueBuilder } from "../../../../../partial/forms/form-tamplate";
import { CatalogItemType } from "../../../../../../@models/medical/catalog-item-view";
import { debounceTime, map, startWith } from "rxjs/operators";
import { Functions } from "../../../../../../../@util/functions";
import { MatAutocompleteTrigger, MatDialogRef } from "@angular/material";
import { UserTempService } from "../../../../../../@core/userTemp.service";
import { DataSortService } from "../../../../../../@core/data-sort.service";
import { MedicalSourcesService } from "../../../../../../@core/medical-sources.service";
import { LoadingService } from "../../../../../../../@util/loading/loading.service";
import { MEDICAL_TESTS_MODE } from "../../../../../partial/partial-medical-tests/partial-medical-tests.config";
import { LEVEL_REF_ITEMS_ID } from "../../../status/intake-form/intake.config";
var creationSteps = {
    'initial': 1,
    'initialFiling': 2,
    'requestForReconsideration': 3,
    'requestForHearing': 4,
    'newDoctor': 5,
    'medicalTests': 6,
    'nextDoctorCreated': 7,
    'finished': 8
};
var creationCheckBoxKeys = {
    'initialClaim': 0,
    'requestForReconsideration': 1,
    'requestForHearing': 2,
    'returning': 3,
    'returningHospital': 4,
    'returningOthers': 5
};
var finishedSteps = {
    'addAnotherDoctor': 1,
    'addHospitalization': 2,
    'addDoctorsVisit': 3,
    'addOtherTreatingSource': 4,
    'returning': 5
};
var DoctorFormNewComponent = /** @class */ (function () {
    function DoctorFormNewComponent(medicalService, medicalSourcesService, formService, fb, addressService, route, userInfo, user, userInfoService, userTempService, dateService, dialog, dialogRef, componentData, router, formBuilder, dataSortService, loadingService, navService) {
        var _this = this;
        this.medicalService = medicalService;
        this.medicalSourcesService = medicalSourcesService;
        this.formService = formService;
        this.fb = fb;
        this.addressService = addressService;
        this.route = route;
        this.userInfo = userInfo;
        this.user = user;
        this.userInfoService = userInfoService;
        this.userTempService = userTempService;
        this.dateService = dateService;
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.componentData = componentData;
        this.router = router;
        this.formBuilder = formBuilder;
        this.dataSortService = dataSortService;
        this.loadingService = loadingService;
        this.navService = navService;
        this.updateDoctorsList = new EventEmitter();
        this.MEDICAL_TESTS_MODE = MEDICAL_TESTS_MODE;
        this.title = 'What type of claim or appeal are we filling on your behalf?';
        this.formLayout = {
            treat: {
                element: {
                    control: 'form-row'
                }
            },
            treats: {
                element: {
                    host: 'form-element'
                }
            },
            speciality: {
                element: {
                    host: 'form-element'
                }
            },
            type: {
                element: {
                    label: 'form-label'
                }
            },
            visits: {
                element: {
                    container: 'row-mb',
                    control: 'form-row',
                }
            },
            firstSeen: {
                element: {
                    host: 'form-element form-element_x2 form-element_br2-full'
                }
            },
            lastSeen: {
                element: {
                    host: 'form-element form-element_x2 form-element_br2-full'
                }
            },
            nextVisit: {
                element: {
                    host: 'form-element form-element_x2 form-element_br2-full'
                }
            },
            phones: {
                element: {
                    container: 'row-mb',
                    control: 'form-row',
                }
            },
            contact_phone: {
                element: {
                    host: 'form-element form-element_x2'
                }
            },
            contact_press: {
                element: {
                    host: 'form-element'
                }
            },
            contact_ext: {
                element: {
                    host: 'form-element'
                }
            },
            contact_fax: {
                element: {
                    container: 'row-mb'
                }
            },
            contact_address: {
                element: {
                    container: 'row-mb'
                }
            },
            contact_address2: {
                element: {
                    container: 'row-mb'
                }
            }, contact_cyti: {
                element: {
                    container: 'row-mb'
                }
            },
            state_zip: {
                element: {
                    control: 'form-row',
                    container: 'row-mb'
                }
            },
            contact_state: {
                element: {
                    host: 'form-element'
                }
            },
            contact_zip: {
                element: {
                    host: 'form-element label-accent'
                }
            },
            // callerPhones: <DynamicFormControlLayout>{
            // 	element: {
            // 		control: 'form-row',
            // 	}
            // },
            medicalCondition: {
                element: {
                    host: 'col-lg-12 row-mb-half',
                    label: 'form-label form-label_full-width row-mb',
                    group: 'row row_conditions'
                },
            }
        };
        this.formTemplate = {
            name: STRING_FIELD({ id: 'name', label: 'Name', hidden: true }),
            googlePlaceId: STRING_FIELD({ id: 'googlePlaceId', label: 'Name', hidden: true }),
            practiceName: STRING_FIELD({ id: 'practiceName', label: 'Practice', maxLength: 256 }),
            treats: STRING_FIELD({ id: 'treats', label: 'Treats For', maxLength: 128 }),
            specialty: STRING_FIELD({ id: 'speciality', label: 'Specialty', maxLength: 128, hidden: true }),
            type: DynamicRadioGroupModel({
                id: 'type',
                label: 'Type',
                options: [
                    {
                        value: DoctorType.PCP,
                        label: DoctorType.PCP.toString()
                    },
                    {
                        value: DoctorType.SPECIALIST,
                        label: DoctorType.SPECIALIST.toString()
                    }
                ],
                value: DoctorType.PCP
            }),
            visits: {
                firstSeen: DATE_PICKER({ id: 'firstSeen', label: 'First Seen', editableFor: ["EXECUTIVE_STAFF", "CLIENT"] }),
                lastSeen: DATE_PICKER({ id: 'lastSeen', label: 'Last Seen', editableFor: ["EXECUTIVE_STAFF", "CLIENT"] }),
                nextVisit: DATE_PICKER({ id: 'nextVisit', label: 'Next Visit', editableFor: ["EXECUTIVE_STAFF", "CLIENT"] })
            },
            contact: {
                phone: PHONE({ id: 'contact_phone', label: 'Phone' }),
                press: PRESS({ id: 'contact_press', label: '"Press"' }),
                ext: EXT({ id: 'contact_ext', label: 'Ext.' }),
                fax: PHONE({ id: 'contact_fax', label: 'Business Fax' }),
                street: ADDRESS({ id: 'contact_address' }),
                street2: ADDRESS({ id: 'contact_address2', label: 'Address 2' }),
                city: CYTY({ id: 'contact_cyti' }),
                state: STATE_SELECT({ id: 'contact_state' }),
                zip: ZIP({ id: 'contact_zip' }),
            },
            // callerName: STRING_FIELD({id: 'callerName', label: 'Name', maxLength: 128}),
            // callerPhone: PHONE({id: 'callerPhone', label: 'Phone'}),
            // callerPress: PRESS({id: 'callerPress', label: '"Press"'}),
            // callerExt: EXT({id: 'callerExt', label: 'Ext.'}),
            medicalCondition: DunamicFormArray({
                id: 'medicalCondition',
                label: 'What medical conditions were treated?',
                groupFactory: function () {
                    return [
                        STRING_FIELD({ id: 'value', label: 'Condition', disabled: _this.isDisabled(), maxLength: 64, editableFor: ["EXECUTIVE_STAFF", "CLIENT"] })
                    ];
                },
                editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
            }),
        };
        this.form = {
            name: this.formTemplate.name,
            // recipientName: this.formTemplate.recipient.recipientName,
            googlePlaceId: this.formTemplate.googlePlaceId,
            practiceName: this.formTemplate.practiceName,
            treat: DynamicFormGroupModel({
                id: 'treat',
                group: [
                    // this.formTemplate.treats,
                    this.formTemplate.specialty,
                ]
            }),
            type: this.formTemplate.type,
            contact: DynamicFormGroupModel({
                id: 'contact',
                group: [
                    DynamicFormGroupModel({
                        id: 'phones',
                        group: [
                            this.formTemplate.contact.phone,
                            this.formTemplate.contact.press,
                            this.formTemplate.contact.ext
                        ]
                    }),
                    this.formTemplate.contact.fax,
                    DynamicFormGroupModel({
                        id: 'state_zip',
                        group: [
                            this.formTemplate.contact.zip,
                            this.formTemplate.contact.state,
                        ]
                    }),
                    this.formTemplate.contact.city,
                    this.formTemplate.contact.street,
                    this.formTemplate.contact.street2,
                ]
            }),
            doctor: DynamicFormGroupModel({
                id: 'doctor',
                group: [
                    this.formTemplate.medicalCondition
                ], editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
            }),
            visit: DynamicFormGroupModel({
                id: 'visit',
                editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
                group: [
                    DynamicFormGroupModel({
                        id: 'visits',
                        editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
                        group: [
                            this.formTemplate.visits.firstSeen,
                            this.formTemplate.visits.lastSeen,
                            this.formTemplate.visits.nextVisit
                        ]
                    }),
                ]
            }),
        };
        this.subscribed = true;
        this.state = State.before;
        this.allFormState = State.before;
        this.disabledBtn = false;
        this.condition = "";
        this.formDate = {
            date: null
        };
        this.typeAskingStep = creationSteps.initial;
        this.creationSteps = creationSteps;
        this.initialFormRadioOptions = [{ label: 'Initial Claim', value: creationCheckBoxKeys.initialClaim },
            { label: 'Req. For Reconsideration', value: creationCheckBoxKeys.requestForReconsideration },
            { label: 'Req. For Hearing', value: creationCheckBoxKeys.requestForHearing },
            { label: 'I\'m Returning', value: creationCheckBoxKeys.returning }];
        this.formInitial = {
            levelSelection: RADIO_USER_STATE({
                id: 'levelSelection',
                options: [{ label: 'Initial Claim', value: creationCheckBoxKeys.initialClaim },
                    { label: 'Req. For Reconsideration', value: creationCheckBoxKeys.requestForReconsideration },
                    { label: 'Req. For Hearing', value: creationCheckBoxKeys.requestForHearing },
                    { label: 'I\'m Returning', value: creationCheckBoxKeys.returning }],
                editableFor: ["EXECUTIVE_STAFF", "CLIENT"]
            })
        };
        this.formFinished = {
            levelSelection: RADIO_USER_STATE({
                id: 'nextStepSelection',
                options: [
                    { label: 'Add Another Doctor', value: finishedSteps.addAnotherDoctor },
                    { label: 'Add a Hospitalization', value: finishedSteps.addHospitalization },
                    { label: 'Add a Doctor\'s Visit Summary ', value: finishedSteps.addDoctorsVisit },
                    { label: 'Add a OTHER Treating Source', value: finishedSteps.addOtherTreatingSource },
                    { label: 'Return to my Profile', value: finishedSteps.returning }
                ]
            })
        };
        // doctorDates = {
        // 	dlw: '2021-11-09 00:00:00',
        // 	ic: '2021-05-01 00:00:00',
        // 	recon: '2021-08-11 00:00:00'
        // }
        this.levelRefValue = 'unset';
        this.nameCtrl = new FormControl();
        this.names = [];
        this.catalogItemId = null;
        this.formModel = FormValueBuilder.buildFormModel(this.form);
        this.formGroup = this.formService.createFormGroup(this.formModel);
        this.nameCtrl.setValidators(Validators.required);
        this.sub = navService.back().subscribe(function (j) { return _this.closeDoctorForm({}); });
        this.formModelDate = FormValueBuilder.buildFormModel(this.formDate);
        this.formInitialModel = FormValueBuilder.buildFormModel(this.formInitial);
        this.formInitialGroup = this.formService.createFormGroup(this.formInitialModel);
        this.formFinishedModel = FormValueBuilder.buildFormModel(this.formFinished);
        this.formFinishedGroup = this.formService.createFormGroup(this.formFinishedModel);
        // this.formDate = this.formBuilder.group({
        // 	date: [null, Validators.required],
        // });
        router.events.subscribe(function (val) {
            if (val instanceof NavigationStart) {
                _this.closeDoctorForm({});
            }
        });
    }
    DoctorFormNewComponent.prototype.onMousewheel = function (event) {
        var input = document.querySelector('.mat-input-element');
        input.blur();
        if (this.trigger) {
            this.trigger.closePanel();
        }
    };
    DoctorFormNewComponent.prototype.ngOnDestroy = function () {
        //this._parent.hideHeader = false;
        this.sub.unsubscribe();
    };
    DoctorFormNewComponent.prototype.isDisabled = function () {
        if (this.isCreationPage() || !this.isClaimant()) {
            return false;
        }
        return true;
    };
    DoctorFormNewComponent.prototype._filterNames = function (value) {
        ////console.log(value);
        var filterValue = typeof value === 'string' ? value.toLowerCase() : value.name ? value.name.toLowerCase() : '';
        ////console.log(filterValue);
        return this.names.filter(function (state) { return state.name.toLowerCase().indexOf(filterValue) === 0; });
    };
    DoctorFormNewComponent.prototype.displayFn = function (item) {
        return item ? item.name : undefined;
    };
    DoctorFormNewComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var type, _a, _b, i, element;
            var _this = this;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.hideDropDownOnscroll();
                        this.checkDisabledBtn();
                        this.sslgId = this.getSslgId();
                        if (this.componentData.from) {
                            if (this.componentData.from === 'hospitals' || this.componentData.from === 'other') {
                                type = this.componentData.from === 'hospitals' ? creationCheckBoxKeys.returningHospital : this.componentData.from === 'other' ? creationCheckBoxKeys.returningOthers : null;
                                this.formInitialGroup.controls.levelSelection.setValue(type);
                                this.changeTypeAskingStep(this.creationSteps.newDoctor);
                            }
                        }
                        this.medicalService.setClaimantUserName(this.sslgId);
                        //this.condition = this.route.snapshot.url[1].path;
                        _a = this;
                        return [4 /*yield*/, this.medicalService.getCatalog(CatalogItemType.DOCTOR).toPromise()];
                    case 1:
                        //this.condition = this.route.snapshot.url[1].path;
                        _a.names = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.medicalSourcesService.addTreatingSourcesDoctorDates(this.sslgId).toPromise()];
                    case 2:
                        _b.doctorDates = _c.sent();
                        this.levelRefValue = this.doctorDates.levelReferred;
                        for (i in this.names) {
                            element = this.names[i];
                            this.names[i].label = '';
                            if (element.address != null) {
                                this.names[i].label += element.address;
                            }
                            if (element.city != null) {
                                if (this.names[i].label != '') {
                                    this.names[i].label += ', ';
                                }
                                this.names[i].label += element.city;
                            }
                            if (element.state != null) {
                                if (this.names[i].label != '') {
                                    this.names[i].label += ', ';
                                }
                                this.names[i].label += element.state;
                            }
                            if (element.zip != null) {
                                this.names[i].label += ' ' + element.zip;
                            }
                        }
                        this.filteredNames = this.nameCtrl.valueChanges
                            .pipe(map(function (value) {
                            console.log(value);
                            _this.doctor = null;
                            _this.formTemplate.name = _this.nameCtrl.value;
                            _this.visitsSwitchedOn();
                            if (value.id) {
                                //this.formGroup.reset();
                                _this.formGroup.enable({ onlySelf: true });
                                _this.subscription.unsubscribe();
                                if (value.id != -1) {
                                    if (_this.user.isClaimant()) {
                                        // if (!this.isUserTemp()) {
                                        // 	this.formGroup.get("callerName").disable({onlySelf: true});
                                        // 	this.formGroup.get("callerPhones").disable({onlySelf: true});
                                        // }
                                        _this.formGroup.get("practiceName").disable({ onlySelf: true });
                                        _this.formGroup.get("type").disable({ onlySelf: true });
                                        _this.formGroup.get("contact").disable({ onlySelf: true });
                                        // this.formGroup.get("recipientName").disable({onlySelf: true});
                                    }
                                    setTimeout(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                        var _a;
                                        return tslib_1.__generator(this, function (_b) {
                                            switch (_b.label) {
                                                case 0:
                                                    _a = this;
                                                    return [4 /*yield*/, this.medicalService.getCatalogItem(CatalogItemType.DOCTOR, value.id).toPromise()];
                                                case 1:
                                                    _a.doctor = _b.sent();
                                                    return [4 /*yield*/, this.setFormData()];
                                                case 2:
                                                    _b.sent();
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); });
                                }
                                else {
                                    //this.formGroup.enable({onlySelf: true});
                                    _this.addressService.getPlaceInfo(value.placeId).subscribe(function (j) {
                                        _this.formTemplate.name.autocompleteSelectedValue = null;
                                        if (j.zip)
                                            _this.formTemplate.contact.zip.valueUpdates.next(j.zip);
                                        if (j.state)
                                            _this.formTemplate.contact.state.valueUpdates.next(j.state);
                                        if (j.city)
                                            _this.formTemplate.contact.city.valueUpdates.next(j.city);
                                        if (j.phone) {
                                            _this.formTemplate.contact.phone.valueUpdates.next(j.phone);
                                        }
                                        if (j.locations)
                                            _this.formTemplate.contact.street.valueUpdates.next((j.streetNumber ? j.streetNumber + ' ' : '') + (j.locations ? j.locations : ''));
                                    });
                                }
                            }
                            else {
                                if (!_this.subscribed) {
                                    _this.medicalService.setMedicalConditionsTemplate([]);
                                    _this.subscribeZipCode();
                                }
                                _this.formGroup.enable({ onlySelf: true });
                                setTimeout(function () {
                                    _this.formGroup.reset();
                                    _this.form.type.valueUpdates.next(DoctorType.PCP);
                                });
                            }
                            return value;
                        }), startWith(''), debounceTime(300), map(function (state) {
                            _this.onStateChanges();
                            if (state) {
                                var names = _this._filterNames(state);
                                // if (typeof state === 'string' && state.length > 3 || state.name) {
                                // 	let query = typeof state === 'string' ? state : state.name;
                                // 	this.addressService.findPlace(query, "DOCTOR").pipe(map((place: any) => {
                                // 		return place.map(j => {
                                // 			return {
                                // 				id: -1,
                                // 				name: j.title,
                                // 				label: j.description,
                                // 				...j
                                // 			};
                                // 		})
                                // 	})).subscribe(j => {
                                // 		names.push(...j);
                                // 	});
                                // }
                                return names;
                            }
                            return _this.names.slice().sort(_this.dataSortService.sortAlphabeticallyByKey('name'));
                        }));
                        this.subscribeZipCode();
                        this.oldFormData = FormValueBuilder.getData(this.form);
                        this.oldSourceNameData = this.nameCtrl.value ? this.nameCtrl.value : '';
                        return [2 /*return*/];
                }
            });
        });
    };
    DoctorFormNewComponent.prototype.hideDropDownOnscroll = function () {
        var _this = this;
        document.querySelector('.mat-dialog-container').addEventListener('scroll', function () {
            var input = document.querySelector('.mat-input-element');
            input.blur();
            if (_this.trigger) {
                _this.trigger.closePanel();
            }
        });
    };
    DoctorFormNewComponent.prototype.getSslgId = function () {
        return this.componentData.sslgId;
    };
    DoctorFormNewComponent.prototype.onChange = function ($event) {
        if ($event.model) {
            if ($event.model.id === 'type') {
                this.hideSpecialty($event.model.value);
            }
        }
    };
    DoctorFormNewComponent.prototype.onChangeDate = function ($event) {
        //console.log($event);
        if ($event.target) {
            this.formDate['date'] = $event.target.value;
        }
    };
    DoctorFormNewComponent.prototype.isCreationPage = function () {
        return true;
    };
    DoctorFormNewComponent.prototype.visitsSwitchedOn = function () {
        this.formTemplate.visits.firstSeen.disabled = false;
        this.formTemplate.visits.lastSeen.disabled = false;
        this.formTemplate.visits.nextVisit.disabled = false;
    };
    DoctorFormNewComponent.prototype.visitsSwitchedOff = function () {
        this.formTemplate.visits.firstSeen.disabled = true;
        this.formTemplate.visits.lastSeen.disabled = true;
        this.formTemplate.visits.nextVisit.disabled = true;
    };
    DoctorFormNewComponent.prototype.subscribeZipCode = function () {
        var _this = this;
        this.subscription = this.formTemplate.contact.zip.valueUpdates.subscribe(function (j) {
            if (j && j.length == 5) {
                _this.addressService.findAddress(j).subscribe(function (addr) {
                    if (addr && addr.state) {
                        _this.formTemplate.contact.state.valueUpdates.next(addr.state);
                        _this.formTemplate.contact.city.valueUpdates.next(addr.city);
                    }
                });
            }
            _this.subscribed = true;
        });
    };
    DoctorFormNewComponent.prototype.setFormData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                setTimeout(function () {
                    if (_this.doctor !== null) {
                        try {
                            var street = _this.doctor.contact.street;
                            _this.doctor.contact.street = street.replace(/,/g, '');
                        }
                        catch (e) {
                        }
                        FormValueBuilder.setData(_this.doctor, _this.formTemplate);
                        setTimeout(function () {
                            _this.hideSpecialty(_this.formTemplate.type.value);
                        });
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    DoctorFormNewComponent.prototype.checkDisabledBtn = function () {
        if (!this.userInfo.getCurrentUserNameIsSystem()) {
            this.disabledBtn = true;
        }
        else {
            this.disabledBtn = false;
        }
    };
    DoctorFormNewComponent.prototype.getBtnTitle = function () {
        var title = 'Add a New Medical Source';
        return title;
    };
    DoctorFormNewComponent.prototype.getBtnName = function () {
        return 'Add';
    };
    DoctorFormNewComponent.prototype.showName = function () {
        return true;
    };
    DoctorFormNewComponent.prototype.onBlur = function ($event) {
    };
    DoctorFormNewComponent.prototype.hideSpecialty = function (typeValue) {
        var _this = this;
        setTimeout(function () {
            _this.formTemplate.specialty.hidden = typeValue === DoctorType.PCP;
            _this.formLayout.treat.element.control = typeValue === DoctorType.PCP ? '' : 'form-row';
            _this.formLayout.treats.element.host = typeValue === DoctorType.PCP ? '' : 'form-element';
        });
    };
    DoctorFormNewComponent.prototype.onFocus = function ($event) {
    };
    DoctorFormNewComponent.prototype.closeDoctorForm = function (cmd) {
        // this.router.navigate([this.route.snapshot.queryParams['returnUrl']],
        // 	{queryParams: {page: this.route.snapshot.queryParams['page']}});
        // this._parent.hideHeader = false;
        // this.dialogRef.close();
        document.querySelectorAll('.mat-drawer-content').forEach(function (el) {
            el.style.overflow = 'scroll';
        });
        this.dialogRef.close(cmd);
    };
    DoctorFormNewComponent.prototype.saveDoctor = function (cmd) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, arrayModel, cond, i, value, nextVisit, firstSeen, lastSeen, particularDate, typeClaimantAppeal, dateIc, dateRecon, dateHrg, treatment, medicalTests, medicalTestsList, medications, medicationsList, doc;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.nameCtrl.value) {
                            return [2 /*return*/, false];
                        }
                        this.formTemplate.name = this.nameCtrl.value.name ? this.nameCtrl.value.name : this.nameCtrl.value;
                        data = FormValueBuilder.getData(this.formTemplate);
                        data.name = typeof this.nameCtrl.value === 'string' ? this.nameCtrl.value : this.nameCtrl.value.name;
                        if (typeof this.nameCtrl.value !== 'string') {
                            data.id = this.nameCtrl.value.id;
                        }
                        if (!this.doctor) {
                            data.googlePlaceId = this.nameCtrl.value.placeId ? this.nameCtrl.value.placeId : null;
                        }
                        data.sslgId = this.sslgId;
                        data.pageType = "new";
                        arrayModel = this.formGroup.controls['doctor'].controls['medicalCondition'];
                        cond = [];
                        for (i = 0; i < arrayModel.controls.length; i++) {
                            value = arrayModel.controls[i].value.value;
                            if (value != "null" && value) {
                                cond.push(value);
                            }
                        }
                        data.medicalConditions = cond.join('-||-');
                        nextVisit = data.visits.nextVisit;
                        data.visits.nextVisit = this.dateService.getDateWithoutHours(nextVisit);
                        firstSeen = data.visits.firstSeen;
                        data.visits.firstSeen = this.dateService.getDateWithoutHours(firstSeen);
                        lastSeen = data.visits.lastSeen;
                        data.visits.lastSeen = this.dateService.getDateWithoutHours(lastSeen);
                        particularDate = {};
                        if (this.dateLastWorked) {
                            particularDate.dateLastWorked = this.dateLastWorked;
                        }
                        else if (this.dateIC) {
                            particularDate.dateIC = this.dateIC;
                        }
                        else if (this.dateRecon) {
                            particularDate.dateRecon = this.dateRecon;
                        }
                        typeClaimantAppeal = this.formInitialGroup.controls.levelSelection.value;
                        dateIc = +typeClaimantAppeal === +creationCheckBoxKeys.initialClaim ? this.dateService.getDateWithoutHours(this.formDate.date) : null;
                        dateRecon = +typeClaimantAppeal === +creationCheckBoxKeys.requestForReconsideration ? this.dateService.getDateWithoutHours(this.formDate.date) : null;
                        dateHrg = +typeClaimantAppeal === +creationCheckBoxKeys.requestForHearing ? this.dateService.getDateWithoutHours(this.formDate.date) : null;
                        treatment = this.medicalTestsData ? this.medicalTestsData.treatment : null;
                        medicalTests = this.medicalTestsData ? this.medicalTestsData.showMedicalTests : null;
                        medicalTestsList = this.medicalTestsData ? this.medicalTestsData.medicalTestsList : null;
                        medications = this.medicationsData ? this.medicationsData.showMedications : null;
                        medicationsList = this.medicationsData ? this.medicationsData.medications : null;
                        return [4 /*yield*/, this.medicalSourcesService.addTreatingSourcesDoctor(this.sslgId, data, typeClaimantAppeal, dateIc, dateRecon, dateHrg, treatment, medicalTests, medicalTestsList, medications, medicationsList)];
                    case 1:
                        doc = _a.sent();
                        this.docNewVisitId = doc.id;
                        this.resetPendingDate();
                        this.dialog.show("THANK YOU! You have successfully added this treating source to status star", ["OK"]);
                        this.nameCtrl.reset();
                        this.formGroup.reset();
                        this.doctor = null;
                        this.updateDoctorsList.next(Math.random());
                        this.changeTypeAskingStep(this.creationSteps.finished);
                        return [2 /*return*/];
                }
            });
        });
    };
    DoctorFormNewComponent.prototype.onStateChanges = function () {
        var dataNew = FormValueBuilder.getData(this.form);
        var sourceNew = this.nameCtrl.value ? this.nameCtrl.value : '';
        if (this.oldFormData != '' && this.oldSourceNameData != '' && dataNew && sourceNew) {
            var isChanges = Functions.equals(this.oldFormData, dataNew);
            var isChangesSource = Functions.equals(this.oldSourceNameData, sourceNew);
            if (!isChanges || !isChangesSource) {
                this.state = State.after;
            }
            else {
                this.state = State.before;
            }
        }
    };
    DoctorFormNewComponent.prototype.isInputDirty = function () {
        return this.state == State.after;
    };
    DoctorFormNewComponent.prototype.isClaimant = function () {
        return this.userInfoService.isClaimant();
    };
    DoctorFormNewComponent.prototype.showSave = function () {
        // if(this.isClaimant() && this.condition!='new'){
        // 	this.visitsSwitchedOff();
        //
        // 	return false;
        // }
        return true;
    };
    DoctorFormNewComponent.prototype.isApp = function () {
        var w = window.innerWidth;
        if (w < 1100) {
            return true;
        }
        return false;
    };
    DoctorFormNewComponent.prototype.initMedicalCondition = function (catalogItemId) {
        var medicalCondition = this.doctor.medicalConditions;
        var medicalConditions = medicalCondition.split("-||-");
        for (var i = 0; i < this.formTemplate.medicalCondition.groups.length; i++) {
            this.formTemplate.medicalCondition.removeGroup(i);
        }
        this.insertMedicalCondition();
        this.initMedicalConditions(medicalConditions);
    };
    DoctorFormNewComponent.prototype.initMedicalConditions = function (medicalConditions) {
        if (!medicalConditions)
            return;
        if (medicalConditions.length > 1) {
            for (var i = 1; i < medicalConditions.length; i++) {
                this.insertMedicalCondition();
            }
        }
        if (medicalConditions.length > 0)
            this.formGroup.controls['doctor'].controls['medicalCondition'].patchValue(medicalConditions.map(function (j) {
                return { value: j };
            }));
    };
    // Medical conditions
    DoctorFormNewComponent.prototype.insertMedicalCondition = function () {
        var arrayModel = this.formService.findById('medicalCondition', this.formModel);
        this.formService.insertFormArrayGroup(arrayModel.groups.length, this.formGroup.controls['doctor']
            .controls['medicalCondition'], arrayModel);
    };
    DoctorFormNewComponent.prototype.remove = function (context, index) {
        this.formService.removeFormArrayGroup(index, this.formGroup.controls['doctor'].controls['medicalCondition'], context);
    };
    DoctorFormNewComponent.prototype.showAddBtn = function (context, index) {
        if (this.isCreationPage() || !this.isClaimant()) {
            return context.groups.length - 1 === index;
        }
        return false;
    };
    DoctorFormNewComponent.prototype.showRemoveBtn = function (context, index) {
        if (this.isCreationPage() || !this.isClaimant()) {
            return context.groups.length > 1;
        }
        return false;
    };
    DoctorFormNewComponent.prototype.typeChanged = function (type) {
        if (type === 'DOCTOR') {
            this.form.doctor.hidden = false;
        }
        else {
            this.form.doctor.hidden = true;
        }
    };
    DoctorFormNewComponent.prototype.canAccess = function () {
        if (this.userInfoService.isClaimant() || this.userInfoService.isSslgStaff() || this.userInfoService.isStaff()) {
            return true;
        }
        return false;
    };
    DoctorFormNewComponent.prototype.cancel = function () {
        this.closeDoctorForm({});
    };
    DoctorFormNewComponent.prototype.isUserTemp = function () {
        return this.userTempService.isTempUser();
    };
    DoctorFormNewComponent.prototype.changeTypeAskingStep = function (step) {
        this.typeAskingStep = step;
        if (this.isInitialStep()) {
            this.title = 'What type of claim or appeal are we filling on your behalf?';
        }
        if (this.isInitialFilingStep()) {
            this.formDate.date = new Date(this.doctorDates.dlw);
            this.title = 'Initial Claim Filing';
        }
        if (this.isRequestForReconsiderationStep()) {
            this.formDate.date = new Date(this.doctorDates.ic);
            this.title = 'Request For Reconsideration';
        }
        if (this.isRequestForHearingStep()) {
            this.formDate.date = new Date(this.doctorDates.recon);
            this.title = 'Request For Hearing';
        }
        if (this.isNewDoctorStep()) {
            this.title = 'New Doctor';
        }
        if (this.isMedicalTestsStep()) {
            var doctorName = this.nameCtrl.value.name ? this.nameCtrl.value.name : this.nameCtrl.value;
            this.title = "Medical Tests <span>" + doctorName + "</span>";
        }
        if (this.isNextDoctorCreatedStep()) {
            var doctorName = this.nameCtrl.value.name ? this.nameCtrl.value.name : this.nameCtrl.value;
            this.title = "Medications <span>" + doctorName + "</span>";
        }
        if (this.isFinishedStep()) {
            this.title = "What would you like to do next?";
        }
    };
    DoctorFormNewComponent.prototype.setPendingDate = function () {
        this.resetPendingDate();
        var date = this.formDate.date;
        if (this.isInitialFilingStep()) {
            this.dateLastWorked = date;
        }
        else if (this.isRequestForReconsiderationStep()) {
            this.dateIC = date;
        }
        else if (this.isRequestForHearingStep()) {
            this.dateRecon = date;
        }
    };
    DoctorFormNewComponent.prototype.resetPendingDate = function () {
        this.dateLastWorked = null;
        this.dateIC = null;
        this.dateRecon = null;
    };
    DoctorFormNewComponent.prototype.isInitialStep = function () {
        return this.typeAskingStep === creationSteps.initial;
    };
    DoctorFormNewComponent.prototype.isInitialFilingStep = function () {
        return this.typeAskingStep === creationSteps.initialFiling;
    };
    DoctorFormNewComponent.prototype.isRequestForReconsiderationStep = function () {
        return this.typeAskingStep === creationSteps.requestForReconsideration;
    };
    DoctorFormNewComponent.prototype.isRequestForHearingStep = function () {
        return this.typeAskingStep === creationSteps.requestForHearing;
    };
    DoctorFormNewComponent.prototype.isNewDoctorStep = function () {
        return this.typeAskingStep === creationSteps.newDoctor;
    };
    DoctorFormNewComponent.prototype.isMedicalTestsStep = function () {
        return this.typeAskingStep === creationSteps.medicalTests;
    };
    DoctorFormNewComponent.prototype.isNextDoctorCreatedStep = function () {
        return this.typeAskingStep === creationSteps.nextDoctorCreated;
    };
    DoctorFormNewComponent.prototype.isFinishedStep = function () {
        return this.typeAskingStep === creationSteps.finished;
    };
    DoctorFormNewComponent.prototype.goNext = function () {
        // const doc = await this.medicalService.saveTreatingSourcesDoctor(this.sslgId, data, this.doctor);
        // const doc = this.doctor;
        // this.router.navigate(['/claimants/medical/' + this.sslgId + '/visit-summaries/new'],
        // 	{
        // 		queryParams: {
        // 			returnUrl: this.route.snapshot.queryParams['returnUrl'],
        // 			catalogItemId: doc.id,
        // 			type: doc.type,
        // 		}
        // 	});
    };
    DoctorFormNewComponent.prototype.addHospital = function () {
        this.router.navigate(['/claimants/medical-treating-sources/' + this.sslgId + '/hospitals'], {
            queryParams: {
                returnUrl: this.route.snapshot.queryParams['returnUrl'],
                popup: 'new'
            }
        });
    };
    DoctorFormNewComponent.prototype.closeForm = function (text) {
        //console.log('close test');
    };
    DoctorFormNewComponent.prototype.onFormStateChanged = function ($event) {
        //console.log('changed test', $event);
    };
    DoctorFormNewComponent.prototype.onAllFormStateChanged = function ($event) {
        //console.log('all changed test', $event);
    };
    DoctorFormNewComponent.prototype.saveMedicalTest = function ($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                //console.log('save', $event);
                this.medicalTestsData = $event;
                this.saveDoctor('new');
                return [2 /*return*/];
            });
        });
    };
    DoctorFormNewComponent.prototype.nextMedicalTest = function ($event) {
        this.medicalTestsData = $event;
        this.changeTypeAskingStep(creationSteps.nextDoctorCreated);
    };
    DoctorFormNewComponent.prototype.saveMedication = function ($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.medicationsData = $event;
                this.saveDoctor('new');
                return [2 /*return*/];
            });
        });
    };
    DoctorFormNewComponent.prototype.goToSecondStep = function (type) {
        var selectedStep = type ? type : this.formInitialGroup.controls.levelSelection.value;
        var selectedStepNum;
        switch (selectedStep) {
            case creationCheckBoxKeys.initialClaim:
                selectedStepNum = creationSteps.initialFiling;
                break;
            case creationCheckBoxKeys.requestForReconsideration:
                selectedStepNum = creationSteps.requestForReconsideration;
                break;
            case creationCheckBoxKeys.requestForHearing:
                selectedStepNum = creationSteps.requestForHearing;
                break;
            case creationCheckBoxKeys.returning:
                selectedStepNum = creationSteps.newDoctor;
                break;
        }
        this.changeTypeAskingStep(selectedStepNum);
    };
    DoctorFormNewComponent.prototype.addAnotherDoctor = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.medicalService.getCatalog(CatalogItemType.DOCTOR).toPromise()];
                    case 1:
                        _a.names = _b.sent();
                        this.formFinishedGroup.reset();
                        this.formGroup.reset();
                        this.nameCtrl.setValue({});
                        this.changeTypeAskingStep(creationSteps.newDoctor);
                        return [2 /*return*/];
                }
            });
        });
    };
    DoctorFormNewComponent.prototype.addHospitalization = function () {
        this.closeDoctorForm('new');
        this.addHospital();
    };
    DoctorFormNewComponent.prototype.addADoctorsVisit = function () {
        this.router.navigate(['/claimants/medical/' + this.sslgId + '/visit-summaries/new'], {
            queryParams: {
                returnUrl: '/claimants/medical/' + this.sslgId + '/visit-summaries',
                catalogItemId: this.docNewVisitId,
                type: 'DOCTOR',
            }
        });
    };
    DoctorFormNewComponent.prototype.addOtherTreatingSource = function () {
        document.querySelectorAll('.mat-drawer-content').forEach(function (el) {
            el.style.overflow = 'scroll';
        });
        this.dialogRef.close({});
        this.router.navigate(['/claimants/medical-treating-sources/' + this.sslgId + '/others'], {
            queryParams: {
                returnUrl: this.route.snapshot.queryParams['returnUrl'],
                popup: 'new'
            }
        });
    };
    DoctorFormNewComponent.prototype.returnToMyProfile = function () {
        this.closeDoctorForm('new');
    };
    DoctorFormNewComponent.prototype.isLevelRefStatusInitial = function () {
        return (this.levelRefValue === LEVEL_REF_ITEMS_ID.initial) || (this.levelRefValue === LEVEL_REF_ITEMS_ID.icPending);
    };
    DoctorFormNewComponent.prototype.isLevelRefStatusRecon = function () {
        return (this.levelRefValue === LEVEL_REF_ITEMS_ID.recon) || (this.levelRefValue === LEVEL_REF_ITEMS_ID.rcPending);
    };
    DoctorFormNewComponent.prototype.isLevelRefStatusHRG = function () {
        return (this.levelRefValue === LEVEL_REF_ITEMS_ID.hearing) || (this.levelRefValue === LEVEL_REF_ITEMS_ID.hrgPend) || (this.levelRefValue === LEVEL_REF_ITEMS_ID.hrgSched);
    };
    DoctorFormNewComponent.prototype.getSelectValue = function (item) {
        //console.log(item)
        // const answer = this.initialFormRadioOptions.find(choice => +choice.value === +item.answer);
        // return answer;
    };
    DoctorFormNewComponent.prototype.onRadioChange = function ($event) {
        this.formInitialGroup.controls.levelSelection.setValue($event.value);
    };
    DoctorFormNewComponent.prototype.isRadioChecked = function (item, option) {
        //console.log(item, option)
    };
    DoctorFormNewComponent.prototype.isRadioDisabled = function (val) {
        if (val.value === 0) {
            return (this.isLevelRefStatusRecon() || this.isLevelRefStatusHRG());
        }
        if (val.value === 1) {
            return this.isLevelRefStatusInitial() || this.isLevelRefStatusHRG();
        }
        if (val.value === 2) {
            return this.isLevelRefStatusRecon() || this.isLevelRefStatusInitial();
        }
        return false;
    };
    DoctorFormNewComponent.prototype.test = function (date) {
        console.log(date);
    };
    return DoctorFormNewComponent;
}());
export { DoctorFormNewComponent };
