import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { DynamicFormService } from "@ng-dynamic-forms/core";
import { FormValueBuilder } from "../../../../../partial/forms/form-tamplate";
import { STRING_FIELD } from "../../../../../../../@util/dynamic-form/dynamic-controlls";
import { MedicalService } from "../../../../../../@core/medical.service";
import { DynamicForm } from "../../../../../../../@util/dynamic-form/dynamic-form";
import { DynamicFormGroupModel, DynamicRadioGroupModel, DynamicSelectModel, DynamicTextAreaModel } from "../../../../../../../@util/ui-material/form-controls";
import { MEDICAL_TESTS_MODE } from "../../../../../partial/partial-medical-tests/partial-medical-tests.config";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialogRef } from "@angular/material";
var creationSteps = {
    'initial': 1,
    'medication': 2,
};
var ClaimantMedicationsNewComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ClaimantMedicationsNewComponent, _super);
    function ClaimantMedicationsNewComponent(formService, route, dialogRef, componentData, router, medicalService) {
        var _this = _super.call(this) || this;
        _this.formService = formService;
        _this.route = route;
        _this.dialogRef = dialogRef;
        _this.componentData = componentData;
        _this.router = router;
        _this.medicalService = medicalService;
        _this.MEDICAL_TESTS_MODE = MEDICAL_TESTS_MODE;
        _this.saveThirdStepData = new EventEmitter();
        _this.title = 'In what way would you like to add a medication?';
        _this.typeAskingStep = creationSteps.initial;
        _this.formLayout = {
            left: {
                element: {
                    host: 'col-lg-6 col-12',
                    control: 'row',
                    children: 'col-12 row-mb'
                },
            },
            right: {
                element: {
                    host: 'col-lg-6 col-12',
                    control: 'row',
                    children: 'col-12 row-mb'
                },
            }
        };
        _this.form = {
            type: DynamicRadioGroupModel({
                id: 'type',
                label: '',
                value: 'DOCTOR',
                options: [
                    { value: 'DOCTOR', label: 'DOCTOR' },
                    { value: 'HOSPITAL', label: 'HOSPITAL' },
                    { value: 'OTHER', label: 'OTHER' },
                ],
                editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
            }),
            catalogItemId: DynamicSelectModel({
                id: 'catalogItemId',
                label: 'Name',
                required: true,
                validators: {
                    required: null
                },
                errorMessages: {
                    required: 'Required'
                },
                value: '', editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
            }),
            name: STRING_FIELD({ id: 'medication', label: 'Medication', editableFor: ["EXECUTIVE_STAFF", "CLIENT"], required: true, errorMessages: { required: 'Required' } }),
            reason: DynamicTextAreaModel({ id: 'reason', label: 'Reason for Taking', editableFor: ["EXECUTIVE_STAFF", "CLIENT"], required: true, errorMessages: { required: 'Required' } }),
            sideEffects: DynamicTextAreaModel({ id: 'sideEffects', label: 'Side Effects', editableFor: ["EXECUTIVE_STAFF", "CLIENT"] }),
        };
        _this.formTemplate = {
            left: DynamicFormGroupModel({
                id: 'left',
                group: [
                    _this.form.type,
                    _this.form.catalogItemId,
                ],
                editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
            }),
            right: DynamicFormGroupModel({
                id: 'right',
                group: [
                    _this.form.name,
                    _this.form.reason,
                    _this.form.sideEffects
                ], editableFor: ["EXECUTIVE_STAFF", "CLIENT"],
            })
        };
        _this.formModelTemplate = FormValueBuilder.buildFormModel(_this.formTemplate);
        _this.formGroupTemplate = formService.createFormGroup(_this.formModelTemplate);
        return _this;
    }
    Object.defineProperty(ClaimantMedicationsNewComponent.prototype, "selectedType", {
        set: function (value) {
            if (!value)
                return;
            if (this._selectedType != value) {
                this._selectedType = value;
                this.typeChanged(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    ClaimantMedicationsNewComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.sslgId = this.getSslgId();
                        _a = this;
                        return [4 /*yield*/, this.medicalService.getCatalogVisitItem(this.sslgId).toPromise()];
                    case 1:
                        _a.ctatlogItems = _b.sent();
                        this.typeChanged('DOCTOR');
                        FormValueBuilder.resetToDefault(this.form);
                        this.formGroupTemplate.valueChanges.subscribe(function (j) {
                            _this.formStateChanged.emit({ key: _this.formKey, value: FormValueBuilder.isDirty(_this.form) });
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ClaimantMedicationsNewComponent.prototype.getSslgId = function () {
        return this.componentData.sslgId;
    };
    ClaimantMedicationsNewComponent.prototype.initCatalogOptions = function (value) {
        var _this = this;
        setTimeout(function () {
            if (!_this.ctatlogItems[value]) {
                _this.form.catalogItemId.options = [{ label: 'Please add ' + value, value: '', disabled: true }];
            }
            else {
                _this.form.catalogItemId.options = _this.ctatlogItems[value].map(function (item) {
                    return { label: item.name, value: item.id };
                });
            }
        }, 0);
    };
    ClaimantMedicationsNewComponent.prototype.typeChanged = function (type) {
        this.initCatalogOptions(type);
        this.form.catalogItemId.valueUpdates.next(null);
        this.formStateChanged.emit({ key: this.formKey, value: FormValueBuilder.isDirty(this.form) });
    };
    ClaimantMedicationsNewComponent.prototype.ngOnDestroy = function () {
        this.formStateChanged.next({ value: false, key: this.formKey });
    };
    ClaimantMedicationsNewComponent.prototype.changeTypeAskingStep = function (step) {
        this.typeAskingStep = step;
        if (this.isInitialStep()) {
            this.title = 'In what way would you like to add a medication?';
        }
        if (this.isMedicationStep()) {
            this.title = 'New Medication';
        }
    };
    ClaimantMedicationsNewComponent.prototype.isInitialStep = function () {
        return this.typeAskingStep === creationSteps.initial;
    };
    ClaimantMedicationsNewComponent.prototype.isMedicationStep = function () {
        return this.typeAskingStep === creationSteps.medication;
    };
    ClaimantMedicationsNewComponent.prototype.addMedication = function () {
        this.changeTypeAskingStep(creationSteps.medication);
    };
    ClaimantMedicationsNewComponent.prototype.addVisit = function () {
        document.querySelectorAll('.mat-drawer-content').forEach(function (el) {
            el.style.overflow = 'scroll';
        });
        this.dialogRef.close({});
        this.router.navigate(['/claimants/medical/' + this.sslgId + '/visit-summaries/new'], {
            queryParams: {
                returnUrl: '/claimants/medical/' + this.sslgId + '/visit-summaries',
                type: 'DOCTOR',
            }
        });
    };
    ClaimantMedicationsNewComponent.prototype.closeMedicationForm = function (cmd) {
        document.querySelectorAll('.mat-drawer-content').forEach(function (el) {
            el.style.overflow = 'scroll';
        });
        this.dialogRef.close(cmd);
    };
    ClaimantMedicationsNewComponent.prototype.saveMedication = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        FormValueBuilder.setControlsToched(this.formGroupTemplate);
                        if (!this.formGroupTemplate.valid) return [3 /*break*/, 2];
                        data = FormValueBuilder.getData(this.form);
                        data.catalogId = data.catalogItemId;
                        delete data.catalogItemId;
                        delete data.type;
                        return [4 /*yield*/, this.medicalService.addMedication(this.sslgId, data)];
                    case 1:
                        _a.sent();
                        this.closeMedicationForm('new');
                        this.formGroupTemplate.reset();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ClaimantMedicationsNewComponent.prototype.onChange = function ($event) {
        var _this = this;
        if ($event.model.id === 'type') {
            this.typeChanged($event.control.value);
            this.selectedType.next($event.control.value);
            setTimeout(function () {
                if (_this.form.catalogItemId.value &&
                    _this.form.catalogItemId.options
                        .filter(function (j) { return j.value === _this.form.catalogItemId.value; }).length === 0) {
                    _this.form.catalogItemId.valueUpdates.next(null);
                }
            }, 0);
        }
        else if ($event.model.id === 'catalogItemId') {
            FormValueBuilder.setData({ catalogItemId: $event.control.value }, this.form);
        }
    };
    return ClaimantMedicationsNewComponent;
}(DynamicForm));
export { ClaimantMedicationsNewComponent };
