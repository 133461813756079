import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DynamicFormService, } from '@ng-dynamic-forms/core';
import { ADDRESS, CYTY, DATE_PICKER, EXT, PHONE, PRESS, RADIO, STATE_SELECT, STRING_FIELD, ZIP } from '../../../../../../../@util/dynamic-form/dynamic-controlls';
import { MedicalService } from '../../../../../../@core/medical.service';
import { FormValueBuilder } from '../../../../../partial/forms/form-tamplate';
import { ActivatedRoute, Router } from '@angular/router';
import { map, startWith } from 'rxjs/operators';
import { CatalogItemType } from '../../../../../../@models/medical/catalog-item-view';
import { NavigationService } from "../../../../../../@core/navigation.service";
import { AddressService } from "../../../../../../@core/address.service";
import { State } from "../../../../../../test-new-stepper-form/test-form/on-changes";
import { Functions } from "../../../../../../../@util/functions";
import { DynamicFormGroupModel } from "../../../../../../../@util/ui-material/form-controls";
import { MedicalRequestsPaymentsComponent } from "../../medical-requests-payments/medical-requests-payments.component";
import { UserInfoService } from "../../../../../../@core/user-info.service";
import { DialogService } from "../../../../../../../@util/dialog/dialog.service";
import { MatAutocompleteTrigger, MatDialogRef } from "@angular/material";
import { DateService } from "../../../../../../@core/functions/date.service";
import { MedicalSourcesService } from "../../../../../../@core/medical-sources.service";
var creationSteps = {
    'initial': 1,
    'otherTreatment': 2,
    'finished': 3
};
var OtheFormNewComponent = /** @class */ (function () {
    function OtheFormNewComponent(medicalService, formService, addressService, userInfoService, dialog, dateService, medicalSourcesService, dialogRef, componentData, route, router, navService) {
        var _this = this;
        this.medicalService = medicalService;
        this.formService = formService;
        this.addressService = addressService;
        this.userInfoService = userInfoService;
        this.dialog = dialog;
        this.dateService = dateService;
        this.medicalSourcesService = medicalSourcesService;
        this.dialogRef = dialogRef;
        this.componentData = componentData;
        this.route = route;
        this.router = router;
        this.navService = navService;
        this.updateOthersList = new EventEmitter();
        this.title = 'New Other';
        this.formLayout = {
            treat: {
                element: {
                    control: 'form-row'
                }
            },
            treats: {
                element: {
                    host: 'form-element'
                }
            },
            speciality: {
                element: {
                    host: 'form-element'
                }
            },
            type: {
                element: {
                    label: 'form-label'
                }
            },
            phones: {
                element: {
                    container: 'row-mb',
                    control: 'form-row',
                }
            },
            approximateDatesRow: {
                element: {
                    container: 'row-mb',
                    control: 'form-row',
                }
            },
            approximateDates_start: {
                element: {
                    host: 'form-element'
                }
            },
            approximateDates_end: {
                element: {
                    host: 'form-element'
                }
            },
            approximateDates_ongoing: {
                element: {
                    label: 'form-label',
                    host: 'form-element'
                }
            },
            contact_phone: {
                element: {
                    host: 'form-element'
                }
            },
            contact_press: {
                element: {
                    host: 'form-element'
                }
            },
            contact_ext: {
                element: {
                    host: 'form-element'
                }
            },
            contact_fax: {
                element: {
                    container: 'row-mb'
                }
            },
            contact_address: {
                element: {
                    container: 'row-mb'
                }
            },
            contact_address2: {
                element: {
                    container: 'row-mb'
                }
            },
            contact_cyti: {
                element: {
                    container: 'row-mb'
                }
            },
            state_zip: {
                element: {
                    control: 'form-row',
                    container: 'row-mb'
                }
            },
            contact_state: {
                element: {
                    host: 'form-element'
                }
            },
            contact_zip: {
                element: {
                    host: 'form-element label-accent'
                }
            },
            callerPhones: {
                element: {
                    control: 'form-row',
                }
            },
            callerPhone: {
                element: {
                    host: 'form-element form-element_x2'
                }
            },
            callerPress: {
                element: {
                    host: 'form-element'
                }
            },
            callerExt: {
                element: {
                    host: 'form-element'
                }
            },
        };
        this.formTemplate = {
            name: STRING_FIELD({ id: 'name', label: 'Name', hidden: true }),
            recipient: {
                recipientName: STRING_FIELD({
                    id: 'recipientName',
                    label: 'Person To Contact',
                    maxLength: 128,
                    required: true,
                    validators: { required: null }
                }),
            },
            contact: {
                phone: PHONE({ id: 'contact_phone', label: 'Phone' }),
                press: PRESS({ id: 'contact_press', label: '"Press"' }),
                ext: EXT({ id: 'contact_ext', label: 'Ext.' }),
                fax: PHONE({ id: 'contact_fax', label: 'Business Fax' }),
                street: ADDRESS({ id: 'contact_address' }),
                street2: ADDRESS({ id: 'contact_address2', label: 'Address 2' }),
                city: CYTY({ id: 'contact_cyti' }),
                state: STATE_SELECT({ id: 'contact_state' }),
                zip: ZIP({ id: 'contact_zip' }),
            },
            approximateDates: {
                start: DATE_PICKER({ id: 'approximateDates_start', editableFor: ["EXECUTIVE_STAFF", "CLIENT"], label: 'Start' }),
                end: DATE_PICKER({ id: 'approximateDates_end', editableFor: ["EXECUTIVE_STAFF", "CLIENT"], label: 'End' }),
                ongoing: RADIO({ id: 'approximateDates_ongoing', editableFor: ["EXECUTIVE_STAFF", "CLIENT"], label: 'Ongoing', value: false }),
            }
        };
        this.form = {
            name: this.formTemplate.name,
            recipientName: this.formTemplate.recipient.recipientName,
            contact: DynamicFormGroupModel({
                id: 'contact',
                group: [
                    DynamicFormGroupModel({
                        id: 'phones',
                        group: [
                            this.formTemplate.contact.phone,
                            this.formTemplate.contact.press,
                            this.formTemplate.contact.ext
                        ]
                    }),
                    this.formTemplate.contact.fax,
                    DynamicFormGroupModel({
                        id: 'state_zip',
                        group: [
                            this.formTemplate.contact.zip,
                            this.formTemplate.contact.state,
                        ]
                    }),
                    this.formTemplate.contact.city,
                    this.formTemplate.contact.street,
                    this.formTemplate.contact.street2,
                ]
            }),
            approximateDates: DynamicFormGroupModel({
                id: 'approximateDates',
                group: [
                    DynamicFormGroupModel({
                        id: 'approximateDatesRow',
                        group: [
                            this.formTemplate.approximateDates.start,
                            this.formTemplate.approximateDates.end,
                            this.formTemplate.approximateDates.ongoing,
                        ]
                    })
                ]
            }),
        };
        this.nameCtrl = new FormControl();
        this.names = [];
        this.subscribed = true;
        this.state = State.before;
        this.typeAskingStep = creationSteps.initial;
        this.formModel = FormValueBuilder.buildFormModel(this.form);
        this.formGroup = this.formService.createFormGroup(this.formModel);
        // this.formGroup.addControl('name', this.nameCtrl);
        this.nameCtrl.setValidators(Validators.required);
        this.sub = navService.back().subscribe(function (j) { return _this.closeDoctorForm(); });
        router.events.subscribe(function (val) {
            if (val) {
                document.querySelectorAll('.mat-drawer-content').forEach(function (el) {
                    el.style.overflow = 'scroll';
                });
                _this.dialogRef.close({});
            }
        });
    }
    OtheFormNewComponent.prototype.onMousewheel = function (event) {
        var input = document.querySelector('.mat-input-element');
        input.blur();
        this.trigger.closePanel();
    };
    OtheFormNewComponent.prototype.ngOnDestroy = function () {
    };
    OtheFormNewComponent.prototype._filterNames = function (value) {
        ////console.log(value);
        var filterValue = typeof value === 'string' ? value.toLowerCase() : value.name ? value.name.toLowerCase() : '';
        ////console.log(filterValue);
        return this.names.filter(function (state) { return state.name.toLowerCase().indexOf(filterValue) === 0; });
    };
    OtheFormNewComponent.prototype.displayFn = function (item) {
        return item ? item.name : undefined;
    };
    OtheFormNewComponent.prototype.showName = function () {
        return true;
    };
    OtheFormNewComponent.prototype.ngAfterViewInit = function () {
    };
    OtheFormNewComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.sslgId = this.getSslgId();
                        _a = this;
                        return [4 /*yield*/, this.medicalService.getCatalog(CatalogItemType.OTHER).toPromise()];
                    case 1:
                        _a.names = _b.sent();
                        this.filteredNames = this.nameCtrl.valueChanges
                            .pipe(map(function (value) {
                            _this.other = null;
                            _this.formTemplate.name = _this.nameCtrl.value;
                            if (value.id) {
                                _this.subscription.unsubscribe();
                                if (value.id != -1) {
                                    _this.formGroup.disable({ onlySelf: true });
                                    _this.formGroup.controls.approximateDates.enable();
                                    setTimeout(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                        var _a;
                                        return tslib_1.__generator(this, function (_b) {
                                            switch (_b.label) {
                                                case 0:
                                                    _a = this;
                                                    return [4 /*yield*/, this.medicalService.getCatalogItem(CatalogItemType.OTHER, value.id).toPromise()];
                                                case 1:
                                                    _a.other = _b.sent();
                                                    return [4 /*yield*/, this.setFormData()];
                                                case 2:
                                                    _b.sent();
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); });
                                }
                                else {
                                    _this.addressService.getPlaceInfo(value.placeId).subscribe(function (j) {
                                        _this.formTemplate.name.autocompleteSelectedValue = null;
                                        if (j.zip)
                                            _this.formTemplate.contact.zip.valueUpdates.next(j.zip);
                                        if (j.state)
                                            _this.formTemplate.contact.state.valueUpdates.next(j.state);
                                        if (j.city)
                                            _this.formTemplate.contact.city.valueUpdates.next(j.city);
                                        if (j.locations)
                                            _this.formTemplate.contact.street.valueUpdates.next((j.streetNumber ? j.streetNumber + ' ' : '') + (j.locations ? j.locations : ''));
                                    });
                                }
                            }
                            else {
                                if (!_this.subscribed) {
                                    _this.medicalService.setMedicalConditionsTemplate([]);
                                    _this.subscribeZipCode();
                                }
                                _this.formGroup.enable({ onlySelf: true });
                                setTimeout(function () {
                                    _this.formGroup.reset();
                                });
                            }
                            return value;
                        }), startWith(''), map(function (state) {
                            _this.onStateChanges();
                            if (state) {
                                var names = _this._filterNames(state);
                                // if (typeof state === 'string' && state.length > 3 || state.name) {
                                // 	let query = typeof state === 'string' ? state : state.name;
                                // 	this.addressService.findPlace(query, "OTHER").pipe(map((place: any) => {
                                // 		return place.map(j=>{
                                // 			return {
                                // 				id: -1,
                                // 				name: j.title,
                                // 				label: j.description,
                                // 				...j
                                // 			};
                                // 		})
                                // 	})).subscribe(j => {
                                // 		names.push(...j);
                                // 	});
                                // }
                                return names;
                            }
                            return _this.names.slice();
                        }));
                        this.subscribeZipCode();
                        this.oldFormData = FormValueBuilder.getData(this.form);
                        this.oldSourceNameData = this.nameCtrl.value ? this.nameCtrl.value : '';
                        return [2 /*return*/];
                }
            });
        });
    };
    OtheFormNewComponent.prototype.getSslgId = function () {
        return this.componentData.sslgId;
    };
    OtheFormNewComponent.prototype.subscribeZipCode = function () {
        var _this = this;
        this.subscription = this.formTemplate.contact.zip.valueUpdates.subscribe(function (j) {
            if (j && j.length == 5) {
                _this.addressService.findAddress(j).subscribe(function (addr) {
                    if (addr && addr.state) {
                        _this.formTemplate.contact.state.valueUpdates.next(addr.state);
                        _this.formTemplate.contact.city.valueUpdates.next(addr.city);
                    }
                });
            }
            _this.subscribed = true;
        });
    };
    OtheFormNewComponent.prototype.setFormData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var street;
            return tslib_1.__generator(this, function (_a) {
                if (this.other !== null) {
                    try {
                        street = this.other.contact.street;
                        this.other.contact.street = street.replace(/,/g, '');
                    }
                    catch (e) {
                    }
                    FormValueBuilder.setData(this.other, this.formTemplate);
                }
                return [2 /*return*/];
            });
        });
    };
    OtheFormNewComponent.prototype.onBlur = function ($event) {
    };
    OtheFormNewComponent.prototype.onChange = function ($event) {
    };
    OtheFormNewComponent.prototype.onFocus = function ($event) {
    };
    OtheFormNewComponent.prototype.closeDoctorForm = function () {
        this.router.navigate([this.route.snapshot.queryParams['returnUrl']], { queryParams: { page: this.route.snapshot.queryParams['page'] } });
        document.querySelectorAll('.mat-drawer-content').forEach(function (el) {
            el.style.overflow = 'scroll';
        });
        // this.dialogRef.close();
    };
    OtheFormNewComponent.prototype.saveDoctor = function (cmd) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, treatmentStart, treatmentEnd, treatmentOngoing, treatmentReason, treatmentDetails, nextVisit, other;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        FormValueBuilder.setControlsToched(this.formGroup);
                        if (!this.nameCtrl.value) return [3 /*break*/, 2];
                        this.formTemplate.name = this.nameCtrl.value.name ? this.nameCtrl.value.name : this.nameCtrl.value;
                        data = FormValueBuilder.getData(this.formTemplate);
                        if (this.nameCtrl && this.nameCtrl.value && this.nameCtrl.value.id) {
                            data.id = this.nameCtrl.value.id;
                        }
                        data.name = this.nameCtrl.value.name ? this.nameCtrl.value.name : this.nameCtrl.value;
                        data.sslgId = this.sslgId;
                        treatmentStart = data && data.approximateDates && data.approximateDates.start ? this.dateService.getDateWithoutHours(data.approximateDates.start) : null;
                        treatmentEnd = data && data.approximateDates && data.approximateDates.end ? this.dateService.getDateWithoutHours(data.approximateDates.end) : null;
                        treatmentOngoing = data && data.approximateDates && data.approximateDates.ongoing ? data.approximateDates.ongoing : null;
                        delete data.approximateDates;
                        treatmentReason = this.otherTreatmentData ? this.otherTreatmentData.treatmentReason : null;
                        treatmentDetails = this.otherTreatmentData ? this.otherTreatmentData.treatmentDetails : null;
                        nextVisit = this.otherTreatmentData ? this.dateService.getDateWithoutHours(this.otherTreatmentData.nextVisit) : null;
                        return [4 /*yield*/, this.medicalSourcesService.addTreatingSourcesOther(this.sslgId, data, treatmentStart, treatmentEnd, treatmentOngoing, treatmentReason, treatmentDetails, nextVisit)];
                    case 1:
                        other = _a.sent();
                        this.otherNewVisitId = other.id;
                        this.dialog.show("THANK YOU! You have successfully added this treating source to status star", ["ok"]);
                        this.nameCtrl.setValue({});
                        this.other = null;
                        this.formGroup.reset();
                        setTimeout(function () {
                            _this.updateOthersList.next(Math.random());
                            _this.changeTypeAskingStep(creationSteps.finished);
                        });
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    OtheFormNewComponent.prototype.onStateChanges = function () {
        var dataNew = FormValueBuilder.getData(this.form);
        var sourceNew = this.nameCtrl.value ? this.nameCtrl.value : '';
        if (this.oldFormData != '' && this.oldSourceNameData != '') {
            var isChanges = Functions.equals(this.oldFormData, dataNew);
            var isChangesSource = Functions.equals(this.oldSourceNameData, sourceNew);
            if (!isChanges || !isChangesSource) {
                this.state = State.after;
            }
            else {
                this.state = State.before;
            }
        }
    };
    OtheFormNewComponent.prototype.isInputDirty = function () {
        return this.state == State.after;
    };
    OtheFormNewComponent.prototype.addVisit = function () {
        this.saveDoctor('addVisit');
    };
    OtheFormNewComponent.prototype.isClaimant = function () {
        return this.userInfoService.isClaimant();
    };
    OtheFormNewComponent.prototype.canAccess = function () {
        if (this.userInfoService.isClaimant() || this.userInfoService.isSslgStaff() || this.userInfoService.isStaff()) {
            return true;
        }
        return false;
    };
    OtheFormNewComponent.prototype.cancel = function () {
        document.querySelectorAll('.mat-drawer-content').forEach(function (el) {
            el.style.overflow = 'scroll';
        });
        this.dialogRef.close(false);
    };
    OtheFormNewComponent.prototype.changeTypeAskingStep = function (step) {
        this.typeAskingStep = step;
        var name = this.nameCtrl && this.nameCtrl.value && this.nameCtrl.value.name ? this.nameCtrl.value.name : this.nameCtrl.value ? this.nameCtrl.value : '';
        if (this.isInitialStep()) {
            this.title = "New Other";
        }
        if (this.isOtherTreatmentStep()) {
            this.title = "Other Treatment <span>" + name + "</span>";
        }
        if (this.isFinishedStep()) {
            this.title = "What would you like to do next?";
        }
    };
    OtheFormNewComponent.prototype.isInitialStep = function () {
        return this.typeAskingStep === creationSteps.initial;
    };
    OtheFormNewComponent.prototype.isOtherTreatmentStep = function () {
        return this.typeAskingStep === creationSteps.otherTreatment;
    };
    OtheFormNewComponent.prototype.isFinishedStep = function () {
        return this.typeAskingStep === creationSteps.finished;
    };
    OtheFormNewComponent.prototype.goToOtherTreatment = function () {
        this.changeTypeAskingStep(creationSteps.otherTreatment);
    };
    OtheFormNewComponent.prototype.backOtherTreatment = function () {
        this.changeTypeAskingStep(creationSteps.initial);
    };
    OtheFormNewComponent.prototype.nextOtherTreatment = function ($event) {
        this.otherTreatmentData = $event;
        this.saveDoctor('new');
    };
    OtheFormNewComponent.prototype.onFormStateChanged = function ($event) {
    };
    OtheFormNewComponent.prototype.onAllFormStateChanged = function ($event) {
    };
    OtheFormNewComponent.prototype.addAnotherDoctor = function () {
        this.router.navigate(['/claimants/medical-treating-sources/' + this.sslgId + '/doctors'], {
            queryParams: {
                returnUrl: this.route.snapshot.queryParams['returnUrl'],
                popup: 'new',
                from: 'other'
            }
        });
    };
    OtheFormNewComponent.prototype.addAVisitSummary = function () {
        this.router.navigate(['/claimants/medical/' + this.sslgId + '/visit-summaries/new'], {
            queryParams: {
                returnUrl: '/claimants/medical/' + this.sslgId + '/visit-summaries',
                catalogItemId: this.otherNewVisitId,
                type: 'OTHER',
            }
        });
    };
    OtheFormNewComponent.prototype.addHospitalization = function () {
        this.router.navigate(['/claimants/medical-treating-sources/' + this.sslgId + '/hospitals'], {
            queryParams: {
                returnUrl: this.route.snapshot.queryParams['returnUrl'],
                popup: 'new'
            }
        });
    };
    OtheFormNewComponent.prototype.addOtherTreatingSource = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.medicalService.getCatalog(CatalogItemType.OTHER).toPromise()];
                    case 1:
                        _a.names = _b.sent();
                        this.nameCtrl.setValue({});
                        this.formGroup.reset();
                        this.changeTypeAskingStep(creationSteps.initial);
                        return [2 /*return*/];
                }
            });
        });
    };
    OtheFormNewComponent.prototype.returnToMyProfile = function () {
        document.querySelectorAll('.mat-drawer-content').forEach(function (el) {
            el.style.overflow = 'scroll';
        });
        this.dialogRef.close({});
    };
    return OtheFormNewComponent;
}());
export { OtheFormNewComponent };
