import { OnInit, OnChanges, SimpleChanges, ElementRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { startOfMonth, getYear, format } from 'date-fns';
import { MatDialog } from "@angular/material/dialog";
import { PickerComponent } from "../picker/picker.component";
// Counter for calculating the auto-incrementing field ID
var counter = 0;
/**
 * Internal library helper that helps to check if value is empty
 * @param value
 */
var isNil = function (value) {
    return (typeof value === 'undefined') || (value === null);
};
var ɵ0 = isNil;
var NgDatepickerComponent = /** @class */ (function () {
    function NgDatepickerComponent(elementRef, dialog) {
        this.elementRef = elementRef;
        this.dialog = dialog;
        // @ViewChild('ngxInputContainer') inputContainer;
        this.datePick = new EventEmitter();
        this.clearDate = new EventEmitter();
        this.onChange = new EventEmitter();
        /**
         * Disable datepicker's input
         */
        this.headless = false;
        /**
         * Set datepicker's visibility state
         */
        this.isOpened = false;
        this.monthNameList = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec',];
        /**
         * Datepicker dropdown position
         */
        this.position = 'bottom-right';
        this.positions = ['bottom-left', 'bottom-right', 'top-left', 'top-right', 'auto'];
        this.disabled = false;
        this.onTouchedCallback = function () { };
        this.onChangeCallback = function () { };
    }
    Object.defineProperty(NgDatepickerComponent.prototype, "selectedValue", {
        set: function (value) {
            if (value) {
                this.value = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    NgDatepickerComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    Object.defineProperty(NgDatepickerComponent.prototype, "value", {
        get: function () {
            return this.innerValue;
        },
        set: function (val) {
            this.innerValue = val;
            this.date = val;
            this.onChangeCallback(this.innerValue);
        },
        enumerable: true,
        configurable: true
    });
    NgDatepickerComponent.prototype.openDialog = function (item) {
        var _this = this;
        var dialogRef = this.dialog.open(PickerComponent, {
            panelClass: 'picker-dialog',
            backdropClass: 'picker-backdrop',
            minWidth: 296,
            data: {
                date: this.date,
                position: this.position,
                barTitle: this.barTitle,
                barTitleFormat: this.barTitleFormat,
                barTitleIfEmpty: this.barTitleIfEmpty,
                useEmptyBarTitle: this.useEmptyBarTitle,
                minYear: this.minYear,
                maxYear: this.maxYear,
                selectedYear: this.selectedYear,
                firstCalendarDay: this.firstCalendarDay,
                startPosition: this.startPosition,
                innerValue: this.innerValue,
                displayFormat: this.displayFormat,
                locale: this.locale,
                displayValue: this.displayValue,
                dayNamesFormat: this.dayNamesFormat,
                item: item
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (!result) {
                _this.isOpened = !_this.isOpened;
                return false;
            }
            _this.value = result;
            _this.datePick.next(result);
            _this.date = result;
            _this.isOpened = false;
        });
    };
    NgDatepickerComponent.prototype.ngOnInit = function () {
        this.date = this.value ? this.value : new Date();
        this.value = this.date;
        this.selectedYear = this.date.getFullYear();
        this.setOptions();
        // Check if 'position' property is correct
        if (this.positions.indexOf(this.position) === -1) {
            throw new TypeError("ng-datepicker: invalid position property value '" + this.position + "' (expected: " + this.positions.join(', ') + ")");
        }
    };
    NgDatepickerComponent.prototype.ngOnChanges = function (changes) {
        if ('options' in changes) {
            this.setOptions();
        }
        this.onChange.next(changes);
    };
    Object.defineProperty(NgDatepickerComponent.prototype, "defaultFieldId", {
        get: function () {
            // Only evaluate and increment if required
            var value = "datepicker-" + counter++;
            Object.defineProperty(this, 'defaultFieldId', { value: value });
            return value;
        },
        enumerable: true,
        configurable: true
    });
    NgDatepickerComponent.prototype.setOptions = function () {
        var today = new Date(); // this const was added because during my tests, I noticed that at this level this.date is undefined
        this.minYear = this.options && this.options.minYear || getYear(today) - 30;
        this.maxYear = this.options && this.options.maxYear || getYear(today) + 30;
        this.displayFormat = this.options && this.options.displayFormat || 'M[/]D[/]YYYY';
        this.barTitleFormat = this.options && this.options.barTitleFormat || 'MMMM YYYY';
        this.dayNamesFormat = this.options && this.options.dayNamesFormat || 'ddd';
        this.barTitleIfEmpty = this.options && this.options.barTitleIfEmpty || this.getCurrentShortDate();
        this.firstCalendarDay = this.options && this.options.firstCalendarDay || 0;
        this.locale = this.options && { locale: this.options.locale } || {};
        this.placeholder = this.options && this.options.placeholder || '';
        this.addClass = this.options && this.options.addClass || {};
        this.addStyle = this.options && this.options.addStyle || {};
        this.fieldId = this.options && this.options.id || this.defaultFieldId;
        this.useEmptyBarTitle = this.options && 'useEmptyBarTitle' in this.options ? this.options.useEmptyBarTitle : true;
        this.formControlName = this.options && this.options.formControlName;
        this.autofocus = this.options && this.options.autofocus;
        this.name = this.options && this.options.name;
        this.tabIndex = this.options && this.options.tabIndex;
        this.required = this.options && this.options.required;
        this.min = this.min && this.options.min;
    };
    NgDatepickerComponent.prototype.toggle = function (item) {
        if (!this.disabled) {
            this.isOpened = !this.isOpened;
            if (this.isOpened) {
                this.openDialog(item);
            }
        }
    };
    NgDatepickerComponent.prototype.writeValue = function (val) {
        if (val) {
            this.date = val;
            this.innerValue = val;
            //this.init();
            this.displayValue = format(this.innerValue, this.displayFormat, this.locale);
            this.barTitle = format(startOfMonth(val), this.barTitleFormat, this.locale);
        }
    };
    NgDatepickerComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    NgDatepickerComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    NgDatepickerComponent.prototype.clearInput = function () {
        this.clearDate.next(null);
    };
    NgDatepickerComponent.prototype.getCurrentShortDate = function () {
        var month = this.monthNameList[new Date().getMonth()];
        var year = new Date().getFullYear();
        return month + ' ' + year;
    };
    return NgDatepickerComponent;
}());
export { NgDatepickerComponent };
export { ɵ0 };
