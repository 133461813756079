import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MedicalService } from '../../../../../@core/medical.service';
import { ActivatedRoute, Router } from "@angular/router";
import { NavigationService } from "../../../../../@core/navigation.service";
import { iTableClassesOptions } from "../../../../../../@util/irs-table/dynamic-table.model";
import { GlobalSaveService } from "../../../../../@core/global-save.service";
import { UserInfoService } from "../../../../../@core/user-info.service";
import { NotificationConfirmationsComponent } from "../../../../notification/confirmations/notification-confirmations.component";
import { MedicalSourcesService } from "../../../../../@core/medical-sources.service";
import { HospitalFormNewComponent } from "./hospital-form-new/hospital-form-new.component";
import { DATE_FORMAT, DEFAULT_DATE } from "../../../../../@config/dateFormat";
import { MDEDICAL_TREATING_SOURCES_STATUSES } from "../../../../../@config/status/medical-treating-sources";
import { LoadingService } from "../../../../../../@util/loading/loading.service";
import { DialogService } from "../../../../../../@util/dialog/dialog.service";
import { DELETE_SOURCE_CONFIRM_MESSAGE } from "../medical-treating-sources.config";
var HospitalsComponent = /** @class */ (function () {
    function HospitalsComponent(medicalService, dialog, route, globalSaveService, router, medicalSourcesService, userInfoService, dialogService, loadingService, navService) {
        var _this = this;
        this.medicalService = medicalService;
        this.dialog = dialog;
        this.route = route;
        this.globalSaveService = globalSaveService;
        this.router = router;
        this.medicalSourcesService = medicalSourcesService;
        this.userInfoService = userInfoService;
        this.dialogService = dialogService;
        this.loadingService = loadingService;
        this.navService = navService;
        this.DATE_FORMAT = DATE_FORMAT;
        this.DEFAULT_DATE = DEFAULT_DATE;
        this.title = 'Hospitals';
        this.closeForm = new EventEmitter();
        this.dynamicTable = [
            {
                column_name: 'Name',
                column_key: 'name',
                column_type: '',
                column_classes: [iTableClassesOptions.width40]
            },
            {
                column_name: 'Phone',
                column_key: 'phoneFormatted',
                column_type: '',
                column_classes: []
            },
            {
                column_name: '',
                column_key: 'button',
                column_type: 'button',
                column_classes: [iTableClassesOptions.width5]
            }
        ]; // for NEW dynamic table
        this.canScrollLeft = false;
        this.canScrollRight = true;
        this.medicalTreatingSourcesStatuses = MDEDICAL_TREATING_SOURCES_STATUSES;
        this.deleteHospitalsArray = [];
        this.dataSource = new MatTableDataSource([]);
        this.sub = navService.back().subscribe(function (j) { return _this.goBack(); });
    }
    HospitalsComponent.prototype.ngOnDestroy = function () {
        this.sub.unsubscribe();
    };
    HospitalsComponent.prototype.goBack = function () {
        this.navService.navigate(['/claimants'], ["" + this.route.snapshot.paramMap.get('sslgId')]);
    };
    HospitalsComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.sslgId = this.route.snapshot.parent.paramMap.get('sslgId');
                this.loadHospitals();
                return [2 /*return*/];
            });
        });
    };
    HospitalsComponent.prototype.loadHospitals = function () {
        var _this = this;
        this.dataSource.paginator = this.paginator;
        this.medicalService.getAllHospitals(this.sslgId).subscribe(function (data) {
            _this.hospitals = data;
            _this.hospitals.sort(function (a, b) {
                var aNext = a.lastInp;
                var bNext = b.lastInp;
                if (aNext < bNext) {
                    return 1;
                }
                if (aNext > bNext) {
                    return -1;
                }
                return 0;
            });
            _this.loadData();
        });
    };
    HospitalsComponent.prototype.loadData = function () {
        var _this = this;
        this.dataSource.data = this.hospitals;
        this.data = this.dataSource.connect();
        if (this.route.snapshot.queryParamMap.get('page')) {
            this.paginator.pageIndex = +this.route.snapshot.queryParamMap.get('page');
            this.data.next(this.dataSource._pageData(this.hospitals));
        }
        var popupNew = this.route.snapshot.queryParamMap.get('popup');
        if (popupNew) {
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: { popup: null },
                queryParamsHandling: 'merge',
            });
            setTimeout(function () {
                _this.addDoctor();
            });
        }
    };
    HospitalsComponent.prototype.hospitalInfo = function (visit) {
        var url = this.getUrl();
        this.router.navigate([url + '/' + visit.id + '/info'], {
            queryParams: {
                returnUrl: url,
                page: this.paginator.pageIndex
            }
        });
    };
    HospitalsComponent.prototype.getUrl = function () {
        return this.router.url.indexOf('?') !== -1 ?
            this.router.url.substring(0, this.router.url.indexOf('?')) : this.router.url;
    };
    HospitalsComponent.prototype.addDoctor = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dialogRef;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                document.querySelectorAll('.mat-drawer-content').forEach(function (el) {
                    el.style.overflow = 'hidden';
                });
                dialogRef = this.dialog.open(HospitalFormNewComponent, {
                    data: {
                        sslgId: this.sslgId
                    },
                    panelClass: ['mobile-popup', 'with-shadow', 'popup-scrollable'],
                    // panelClass: ['mobile-popup', 'with-shadow'],
                    backdropClass: 'mobile-popup__overlay',
                    disableClose: true
                });
                dialogRef.componentInstance.updateHospitalsList.subscribe(function (result) {
                    _this.loadHospitals();
                });
                dialogRef.afterClosed().subscribe(function (result) {
                    if (result) {
                        if (result.cmd) {
                            if (result.cmd == 'addVisit') {
                                _this.router.navigate(['/claimants/medical/' + _this.sslgId + '/visit-summaries/new'], {
                                    queryParams: {
                                        returnUrl: _this.route.snapshot.queryParams['returnUrl'],
                                        catalogItemId: result.data.id,
                                        type: 'HOSPITAL',
                                    }
                                });
                            }
                            else {
                                _this.loadHospitals();
                            }
                        }
                        else if (result == 'new') {
                            _this.loadHospitals();
                        }
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    HospitalsComponent.prototype.isClaimant = function () {
        return this.userInfoService.isClaimant();
    };
    HospitalsComponent.prototype.isSystem = function () {
        return this.userInfoService.getCurrentUserNameIsSystem();
    };
    HospitalsComponent.prototype.delete = function (data, index) {
        var _this = this;
        var dialog = this.dialog.open(NotificationConfirmationsComponent, {
            data: {
                message: "Are you sure you want to delete the source?"
            }
        });
        dialog.afterClosed().subscribe(function (j) {
            try {
                if (j.status) {
                    //alert(data.id)
                    _this.medicalSourcesService.deleteSource(_this.sslgId, data.id).subscribe(function () {
                        var itemIndex = index + _this.paginator.pageSize * _this.paginator.pageIndex;
                        _this.hospitals.splice(itemIndex, 1);
                        _this.loadData();
                    });
                }
            }
            catch (e) {
            }
        });
    };
    HospitalsComponent.prototype.tableScrolled = function ($event) {
        var scrollLeft = $event.target.scrollLeft;
        var scrollWidth = $event.target.scrollWidth;
        var offsetWidth = $event.target.offsetWidth;
        if (scrollLeft > 0) {
            this.canScrollLeft = true;
        }
        else {
            this.canScrollLeft = false;
        }
        if (scrollWidth - scrollLeft > offsetWidth + 5) {
            this.canScrollRight = true;
        }
        else {
            this.canScrollRight = false;
        }
    };
    HospitalsComponent.prototype.getTableHeadClass = function () {
        // if (this.isSystem()) {
        // 	return 'i-btn-2';
        // }
        return 'i-btn-1';
        // if (this.globalSaveService.getStatusTrue()) {
        // 	if (this.isSystem()) {
        // 		return 'i-btn-2';
        // 	}
        // 	return 'i-btn-1';
        // } else {
        // 	if (this.isSystem()) {
        // 		return 'i-btn-2';
        // 	}
        // 	return 'i-btn-0';
        // }
    };
    HospitalsComponent.prototype.isExecutive = function () {
        if (this.userInfoService.getCurrentUserRole() == 'EXECUTIVE_STAFF') {
            return true;
        }
        return false;
    };
    HospitalsComponent.prototype.onMedicalTreatingSourcesStatusChange = function ($event, doctor, select) {
        var _this = this;
        var newValue = $event.value;
        this.medicalSourcesService.changeStatusSource(this.sslgId, doctor.id, newValue).subscribe(function (data) {
            if (data[status] === false) {
                _this.loadingService.showError([data['message']]);
                select.value = doctor.catalogStatus;
            }
            else {
                _this.loadData();
            }
        }, function () {
            select.value = doctor.catalogStatus;
        });
    };
    HospitalsComponent.prototype.getMedicalTreatingSourcesStatus = function (doctor) {
        // if (doctor.catalogStatus) {
        return doctor.catalogStatus;
        // }
        // return 0;
    };
    HospitalsComponent.prototype.getDate = function (date) {
        return date !== this.DEFAULT_DATE ? date : '';
    };
    HospitalsComponent.prototype.toggleDeleteHospitalsArray = function (e, id) {
        var _this = this;
        if (e.checked) {
            var elExist_1;
            this.deleteHospitalsArray.forEach(function (el) {
                if (el.id === id) {
                    elExist_1 = el;
                }
            });
            if (!elExist_1) {
                this.deleteHospitalsArray.push({
                    id: id,
                    sslgId: this.sslgId
                });
            }
        }
        else {
            this.deleteHospitalsArray.forEach(function (el, index) {
                if (el.id === id) {
                    _this.deleteHospitalsArray.splice(index, 1);
                }
            });
        }
    };
    HospitalsComponent.prototype.deleteHospitals = function () {
        var _this = this;
        if (!this.deleteHospitalsArray.length) {
            this.dialogService.show('Please, select at least one Hospital to delete', ['OK']);
            return;
        }
        var dialog = this.dialog.open(NotificationConfirmationsComponent, {
            data: {
                messageHTML: DELETE_SOURCE_CONFIRM_MESSAGE
            }
        });
        dialog.afterClosed().subscribe(function (j) {
            try {
                if (j.status) {
                    _this.medicalSourcesService.deleteSources(_this.deleteHospitalsArray).subscribe(function () {
                        _this.deleteHospitalsArray = [];
                        _this.loadHospitals();
                    });
                }
            }
            catch (e) {
            }
        });
    };
    return HospitalsComponent;
}());
export { HospitalsComponent };
